//var userObject = {userId: 24, name: 'Jack Bauer'};
//localStorage.setObject(localStorageKey, userObject);
Storage.prototype.setObject = function(key, value) {
    this.setItem(key, JSON.stringify(value));
};

//userObject = localStorage.getObject(localStorageKey);
Storage.prototype.getObject = function(key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
};

// eslint-disable-next-line
String.prototype.removeDiacritics = function() {
  return this.replace(/ă/g, 'a').replace(/Ă/g, 'A').replace(/â/g, 'a').replace(/Â/g, 'A').replace(/î/g, 'i').replace(/Î/g, 'I').replace(/ș/g, 's').replace(/Ș/g, 'S').replace(/ț/g, 't').replace(/Ț/g, 'T').trim();
};

// eslint-disable-next-line
String.prototype.toSentenceCase = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// getCurrentDateTime().toYYYYMM()
// eslint-disable-next-line
String.prototype.toYYYYMM = function() {
  return this.substr(0, 7);
};

// getCurrentDateTime().toYYYYMMDD()
// eslint-disable-next-line
String.prototype.toYYYYMMDD = function() {
  return this.split('T')[0];
};

// 'DD/MM/YYYY HH:MM'.toYYYYMMDDHHMMSS() => 'YYYY-MM-DD HH:MM:SS'
// eslint-disable-next-line
String.prototype.toYYYYMMDDHHMMSS = function() {
  const date = this.split(' ')[0];
  const time = this.split(' ')[1];
  var year, month, day, hours, minutes, seconds;

  if (this.match(/^\d\d\d\d-\d\d-\d\d/)) {          // yyyy-mm-dd
    year = date?.split('-')[0];
    month = date?.split('-')[1];
    day = date?.split('-')[2];
  } else if (this.match(/^\d\d\/\d\d\/\d\d\d\d/)) { // dd/mm/yyyy
    year = date?.split('/')[2];
    month = date?.split('/')[1];
    day = date?.split('/')[0];
  }

  hours = time?.split(':')[0] || '00';
  minutes = time?.split(':')[1] || '00';
  seconds = time?.split(':')[2] || '00';

  return `${year}-${month}-${day} ${Boolean(hours) ? hours.padStart(2, '0') : '00'}:${Boolean(minutes) ? minutes.padStart(2, '0') : '00'}:${Boolean(seconds) ? seconds.padStart(2, '0') : '00'}`;
};
// new Date().toTimeString().toHourMinutes() => 'HH:MM'
// eslint-disable-next-line
String.prototype.toHourMinutes = function() {
  return `${this.split(':')[0]}:${this.split(':')[1]}`;
};
// '17:30'.toDateFromHM() => Sun Dec 31 1899 17:30:00 GMT+0144 (Ora standard a Europei de Est)
// eslint-disable-next-line
String.prototype.toDateFromHM = function() {
  return new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), this.split(':')[0], this.split(':')[1]);
};

// eslint-disable-next-line
String.prototype.isValidCnp = function() {
  var i = 0;
  var year = 0;
  var hashResult = 0;
  var cnp = [];
  var hashTable=[2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];

  if (this.length !== 13)
    return false;

  if (this.slice(-6) === '000000')
    return false;

  for (i = 0; i < 13; i++) {
    cnp[i] = parseInt(this.charAt(i), 10);
    if (isNaN(cnp[i]))
      return false;

    if (i < 12)
      hashResult = hashResult + (cnp[i] * hashTable[i]);
  }

  hashResult = hashResult % 11;
  if (hashResult === 10)
    hashResult = 1;

  year = (cnp[1] * 10) + cnp[2];
  switch (cnp[0]) {
    case 1: case 2:
      year += 1900;
      break;

    case 3:
    case 4:
      year += 1800;
      break;

    case 5:
    case 6:
      year += 2000;
      break;

    case 7:
    case 8:
    case 9:
      year += 2000;
      if (year > (parseInt(new Date().getYear(), 10) - 14))
        year -= 100;
      break;

    default:
      return false;
  }

  if (year < 1800 || year > 2099)
    return false;

  return cnp[12] === hashResult;
};

// new Date().toHHMM()
// eslint-disable-next-line
Date.prototype.toHHMM = function() {
  return `${this.getHours().toString().padStart(2, '0')}:${this.getMinutes().toString().padStart(2, '0')}`;
};

// new Date().timeBetween('09:00', '18:00')
// if stop < start then stop value is tomorow
// eslint-disable-next-line
Date.prototype.timeBetween = function(timeStart, timeStop) {
  const dateStart = timeStart.toDateFromHM();
  const dateStop = timeStart < timeStop
    ? timeStop.toDateFromHM()
    : new Date(timeStop.toDateFromHM().setDate(timeStop.toDateFromHM().getDate() + 1));
    return this.getTime() > dateStart.getTime() && this.getTime() < dateStop.getTime();
};

// new Date().addMonths(12)
// eslint-disable-next-line
Date.prototype.addMonths = function(months) {
  var d = this.getDate();
  this.setMonth(this.getMonth() + +months);
  if (this.getDate() !== d)
    this.setDate(0);
  return this;
};

// new Date().addDays(15)
// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  this.setDate(this.getDate() + +days);
  return this;
};

// await File.toBase64() => 'data:image/jpeg;base64,..............'
File.prototype.toBase64 = function() {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(this);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

// arrOfObjs.getValueById(1, 'specialty')
// return the named property of passed id
// if property name is missing, second key is used
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'getValueById', {
  value: function(id, keyValue = false, keyId = 'id') {
    if (this.length === 0) {
      console.error('Provided list is empty!');
      return null;
    }
    if (keyValue === false)
      keyValue = Object.keys(this[0])[1];
    var filteredArray = this.filter(item => item[keyId] === id);
    if (filteredArray.length === 0) {
      console.error('Provided id was not found!', id, this);
      return null;
    }
    if (filteredArray.length > 1) {
      console.error('Provided id is not unique!', id, this);
      return null;
    }
    if (!filteredArray[0].hasOwnProperty(keyValue)) {
      console.error('Provided property name does not exist in filtered object!', keyValue, filteredArray);
      return null;
    }
    if (!filteredArray[0].hasOwnProperty(keyId)) {
      console.error('Provided property ID does not exist in filtered object!', keyId, filteredArray);
      return null;
    }
    return filteredArray[0][keyValue];
  },
  configurable: true
});
// arrOfObjs.getUniqueValues(['id', 'value'])
// return unique array values depending on the passed parameter type:
//    NULL - default value. entire items are compared
//    String - only the passed key will be compared
//    Array - only the passed keys will be compared
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'getUniqueValues', {
  value: function(keys = null) {
    if (keys === null)
      return [...new Set(this.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));

    if (typeof keys === 'string')
      return this.filter((value, item, self) => self.findIndex(t => (t[keys] === value[keys])) === item);

    if (Array.isArray(keys))
      return this.filter((value, item, self) => self.indexOf(value) === item);

    return null;
  },
  configurable: true
});
// arrOfObjs.sum('value', 2)
// return the sum of all `value` keys formatted with two decimals
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sum', {
  value: function(key, decimals = 0) {
    return parseFloat(this.reduce(function(a, b) {
      return +a + (+b[key] || 0);
    }, 0)).toFixed(decimals);
  },
  configurable: true
});
// arrOfObjs.sumByGroups(['array of group by columns'], ['array of columns to be summed up'], ['array of sort by columns'], ['array of dropped columns'])
// return an array of objects like the input with columns summed up after grouping it by some other columns
// the returned value can be sorted by multiple columns
// more than that, you can even drop some properties from the input object structure
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sumByGroups', {
  value: function(groupBy, sumCols = [], sortBy = [], dropCols = []) {
    return [...this.reduce((result, obj) => {
      const uniqueKey = groupBy.reduce((key, col) => key + '-' + obj[col], 'groupBy');

      const item = result.get(uniqueKey) || Object.assign({}, obj, sumCols.reduce((obj, cur) => ({...obj, [cur]: 0}), {}));

      for (var i = 0; i < sumCols.length; i++)
        item[sumCols[i]] += +obj[sumCols[i]];

      return result.set(uniqueKey, item);
    }, new Map()).values()].map(item => {
      for (var i = 0; i < dropCols.length; i++)
        delete item[dropCols[i]];
      return item;
    }).sort((a, b) => sortBy.reduce((bool, key) => bool || (a[key] > b[key] ? 1 : (b[key] > a[key] ? -1 : 0)), 0));
  },
  configurable: true
});
// arrOfObjs.sortByKey('idSpecialty', specialties, 'id', 'specialty')
// sort arrOfObjs by key 'idSpecialty'
// if needed, it looks into 'specialties' array, matching 'idSpecialty' value with 'id' value and compare 'specialty' properties
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sortByKey', {
  value: function(key, matches = false, matchValue = false, matchKey = 'id') {
    if (Boolean(matches))
      return this.sort((a, b) => (matches.getValueById(a[key], matchKey, matchValue) > matches.getValueById(b[key], matchKey, matchValue)) ? 1 : ((matches.getValueById(b[key], matchKey, matchValue) > matches.getValueById(a[key], matchKey, matchValue)) ? -1 : 0));
    else
      return this.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
  }
});
/**
 * Update an element in provided array based on its index.
 * If a property is passed to the function, then only the array[index].property value will be set.
 * When extraValues are passed, those will be added on changed array's item.
 * @param {*} index
 * @param {*} value
 * @param {*} property
 * @param {*} extraValues
 */
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'updateElementInArray', {
  value: function(index, value, property = null, extraValues = {}) {
    return [
      ...this.slice(0, index),
      property === null
        ? value
        : {
            ...this[index],
            [property]: value,
            ...extraValues
          },
      ...this.slice(index + 1)
    ]
  },
  configurable: true
});


export const getCurrentDateTime = () => {
  return (new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000))).toISOString();
};

export const getCurrentDate = () => {
  return getCurrentDateTime().split(' ')[0];
};

export const getLanguage = () => {
  return typeof navigator.languages !== 'undefined' ? navigator.languages[0] : navigator.language;
};

export const getWeekDays = (language = getLanguage()) => {
  var baseDate = new Date(Date.UTC(2020, 2, 2)); // just a Monday
  var weekDays = [];
  for(var i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(language, {weekday: 'long'}));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

export const monthNames = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombrie',
  'Noiembrie',
  'Decembrie'
];

export const monthShortNames = [
  'Ian',
  'Feb',
  'Mar',
  'Apr',
  'Mai',
  'Iun',
  'Iul',
  'Aug',
  'Sep',
  'Oct',
  'Noi',
  'Dec'
];

export const weekDaysNames = [
  'Duminica',
  'Luni',
  'Marti',
  'Miercuri',
  'Joi',
  'Vineri',
  'Sambata'
];