import '../Utils';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CallIcon from '@mui/icons-material/Call';

const Contact = props => {
  /**
   * States
   */

  /**
   * Hooks
   */
  const theme = useTheme();

  /**
   * Getters
   */

  /**
   * Render
   */
  return (
    <Box sx={props.sx}>
      <CardContent>
        <Typography variant='h6' color='primary' align='center' sx={{fontWeight: 'bold'}}>Pizzeria Myo Modern Tulcea</Typography>
      </CardContent>
      <CardContent>
        <Typography variant='subtitle1' align='center'>Adresa: Str. Libertatii colt cu Rezervorului</Typography>
      </CardContent>
      <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant='subtitle1' align='center'>Puteti comanda la numerele de telefon:</Typography>
        <Button component='a' variant='contained' size='small' color='primary' startIcon={<CallIcon />} onClick={() => window.open('tel:+40340108195', '_self')} sx={{mb: 1, minWidth: '160px'}}>0340 108 195</Button>
        <Button component='a' variant='contained' size='small' color='primary' startIcon={<CallIcon />} onClick={() => window.open('tel:+40732663376', '_self')} sx={{mb: 1, minWidth: '160px'}}>0732 MODERN</Button>
        <Button component='a' variant='contained' size='small' color='primary' startIcon={<CallIcon />} onClick={() => window.open('tel:+40749335509', '_self')} sx={{mb: 1, minWidth: '160px'}}>0749 335 509</Button>
        <Typography variant='subtitle1' color='secondary' align='center' sx={{fontWeight: 'bold'}}>Valoarea minima a unei comenzi este de 80 RON</Typography>
      </CardContent>
      <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant='subtitle1' color='primary' align='center' sx={{fontWeight: 'bold'}}>Pentru a va asigura servicii de calitate, preparatele noastre sunt pregatite pe loc si de aceea comanda poate dura intre 30 si 40 minute, iar in perioadele aglomerate, maxim 60 minute.</Typography>
      </CardContent>
      <CardContent>
        <Typography variant='subtitle1' align='center'><a href='mailto:myodyafly@yahoo.com?subject=Site Myo Modern Tulcea' style={{color: theme.palette.primary.main}}>myodyafly@yahoo.com</a></Typography>
      </CardContent>
    </Box>
  );
}

export { Contact };