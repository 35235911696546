import '../Utils';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const photos = [
  { id: '1' , img: 'photo1.jpg' , rows: 2, cols: 1, title: 'Foto 1'  },
  { id: '2' , img: 'photo2.jpg' , rows: 1, cols: 1, title: 'Foto 2'  },
  { id: '3' , img: 'photo3.jpg' , rows: 2, cols: 1, title: 'Foto 3'  },
  { id: '4' , img: 'photo4.jpg' , rows: 2, cols: 1, title: 'Foto 4'  },
  { id: '5' , img: 'photo5.jpg' , rows: 2, cols: 1, title: 'Foto 5'  },
  { id: '17', img: 'photo17.jpg', rows: 1, cols: 1, title: 'Foto 17' },
  { id: '6' , img: 'photo6.jpg' , rows: 1, cols: 2, title: 'Foto 6'  },
  { id: '7' , img: 'photo7.jpg' , rows: 1, cols: 2, title: 'Foto 7'  },
  { id: '8' , img: 'photo8.jpg' , rows: 2, cols: 1, title: 'Foto 8'  },
  { id: '9' , img: 'photo9.jpg' , rows: 2, cols: 1, title: 'Foto 9'  },
  { id: '10', img: 'photo10.jpg', rows: 2, cols: 1, title: 'Foto 10' },
  { id: '11', img: 'photo11.jpg', rows: 2, cols: 1, title: 'Foto 11' },
  { id: '12', img: 'photo12.jpg', rows: 2, cols: 1, title: 'Foto 12' },
  { id: '13', img: 'photo13.jpg', rows: 1, cols: 1, title: 'Foto 13' },
  { id: '14', img: 'photo14.jpg', rows: 1, cols: 1, title: 'Foto 14' },
  { id: '15', img: 'photo15.jpg', rows: 1, cols: 1, title: 'Foto 15' },
  { id: '16', img: 'photo16.jpg', rows: 2, cols: 1, title: 'Foto 16' },
  { id: '18', img: 'photo18.jpg', rows: 2, cols: 1, title: 'Foto 18' },
  { id: '19', img: 'photo19.jpg', rows: 2, cols: 1, title: 'Foto 19' },
];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Photos = props => {
  /**
   * States
   */

  /**
   * Hooks
   */

  /**
   * Getters
   */

  /**
   * Render
   */
  return (
    <Box sx={props.sx}>
      <ImageList variant='quilted' cols={3}
        // sx={{ width: 500, height: 450 }}
        // rowHeight={121}
      >
        {photos.map(photo => (
          <ImageListItem key={photo.id} cols={photo.cols || 1} rows={photo.rows || 1}>
            <img loading='lazy'
              {...srcset(`images/Photos/${photo.img}`, 121, photo.rows, photo.cols)}
              alt={photo.title}
              title={photo.title}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

export { Photos };