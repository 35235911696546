import { firstBy } from 'thenby';

/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
Object.defineProperty(Object.prototype, 'selectRows', {
  value: function({select = null, join = [], where = null, value = null, log = true} = {}) {
    return [];
  },
  configurable: true
});

/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
Object.defineProperty(Object.prototype, 'selectColumn', {
  value: function({select = null, join = [], where = null, value = null, log = false} = {}) {
    if (select === null) {
      console.error(`The 'select' field is mandatory`);
      return null;
    }

    const selectTable = String(select).split('.')[0];
    if (!this.hasOwnProperty(selectTable)) {
      console.error(`Table \`${selectTable}\` does not exists`);
      return null;
    }

    const selectField = String(select).split('.')[1];
    if (!selectField) {
      console.error(`Error parsing first parameter. '${select}' must be formatted as 'table.field'`);
      return null;
    }
    if (!this[selectTable][0].hasOwnProperty(selectField)) {
      console.error(`Field \`${selectTable}\`.\`${selectField}\` does not exists`);
      return null;
    }

    if (where === null) {
      console.error(`The 'where' field is mandatory`);
      return null;
    }
      
    const whereTable = String(where).split('.')[0];
    if (!this.hasOwnProperty(whereTable)) {
      console.error(`Table \`${whereTable}\` does not exists`);
      return null;
    }
      
    const whereField = String(where).split('.')[1];
    if (!whereField) {
      console.error(`Error parsing second parameter. '${where}' must be formatted as 'table.field'`);
      return null;
    }
    if (!this[whereTable][0].hasOwnProperty(whereField)) {
      console.error(`Field \`${whereTable}\`.\`${whereField}\` does not exists`);
      return null;
    }

    if (value === null) {
      console.error(`The 'value' field is mandatory`);
      return null;
    }

    const query = `SELECT \`${selectField}\` FROM \`${selectTable}\` SMART JOIN ${join.length === 0 ? 'ALL' : `\`${join.join('`, `')}\``} WHERE \`${whereTable}\`.\`${whereField}\` = '${value}'`;
    log && console.log(query);
      
    join = join.length === 0
      ? Object.keys(this).filter(table => table !== '_' && table !== whereTable).sort(firstBy(table => table !== selectTable))
      : join.filter(table => table !== '_').sort(firstBy(table => table !== selectTable))
    ;

    var rows = this[whereTable].filter(entity => entity[whereField] === value);
    if (rows.length === 0) {
      console.error(`DB.${whereTable}: No records match the criteria \`${whereTable}\`.\`${whereField}\` = '${value}'`);
      return null;
    }
    const row = rows[0];
    log && rows.length > 1 && console.warn(`Found ${rows.length} records matching the criteria \`${whereTable}\`.\`${whereField}\` = '${value}'\nUsing the first one:`, row);

    if (selectTable === whereTable) {
      return row[selectField];
    }

    log && console.log(row);
    var results = [];
    Object.keys(row).filter(key => key.match(/id[A-Z]/)).forEach(key => {
      join.filter(table => ~Object.keys(this[table][0]).indexOf(key.substring(2).charAt(0).toLowerCase() + key.substring(2).slice(1))).forEach(table => {
        if (Boolean(row[key])) {
          log && console.log(`Join \`${table}\` by key \`${key}\``);
          results.push(this.selectColumn({
            select: select,
            join: join.filter(tableName => tableName !== table),
            where: `${table}.id`,
            value: row[key],
            log: log
          }));
        }
      }, this);
    }, this);

    const result = results.flat().find(value => Boolean(value)) || null;
    log && console.log(`result:`, result);
    return result;
  },
  configurable: true
});