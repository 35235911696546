import { useState, useEffect } from 'react';
import './JsSql';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepPurple, purple } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';

import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ScrollToTop from './Components/ScrollToTop';
import { Home } from './Views/Home';
import { Menu } from './Views/Menu';
import { Contact } from './Views/Contact';
import { Photos } from './Views/Photos';

const theme = createTheme({
  palette: {
    primary: {
      main: deepPurple['A200'],
    },
    secondary: {
      main: purple['A400'],
    }
  },
});

const views = {
  Home:    { component: Home    },
  Menu:    { component: Menu    },
  Contact: { component: Contact },
  Photos:  { component: Photos  },
};

const jsSqlMeta = {
  categories: {},
  ingredients: {},
  measurementUnits: {},
  products: {},
  productIngredients: {},
  productVariants: {},
  variants: {}
};

const categories = [
  { id: '1' , category: 'Pizza'              , image: 'Pizza.jpg', position: 1 , description: '' },
  { id: '2' , category: 'Topping'            , image: ''         , position: 2 , description: '' },
  // { id: '3' , category: 'Shaworma'           , image: 'Shaworma.jpg', position: 3 , description: '' },
  { id: '4' , category: 'Sandwich'           , image: 'Sandwich.jpg'         , position: 4 , description: '' },
  { id: '5' , category: 'Meniuri'            , image: 'Meniuri.jpg'         , position: 5 , description: '' },
  { id: '6' , category: 'Salate'             , image: 'Salate.jpg'         , position: 6 , description: '' },
  { id: '7' , category: 'Paste'              , image: 'Paste.jpg'         , position: 7 , description: '' },
  { id: '8' , category: 'Platouri'           , image: ''         , position: 8 , description: '' },
  { id: '9' , category: 'Desert'             , image: 'Desert.jpg'         , position: 9 , description: '' },
  { id: '10', category: 'Bauturi racoritoare', image: ''         , position: 10, description: '' },
  { id: '11', category: 'Bere'               , image: ''         , position: 11, description: '' },
  { id: '12', category: 'Vinuri'             , image: ''         , position: 12, description: '' },
];
const ingredients = [
  { id: '1' , ingredient: 'sos de rosii'               , isAllergen: false, isFrozen: false },
  { id: '2' , ingredient: 'mozzarella'                 , isAllergen: true , isFrozen: false },
  { id: '3' , ingredient: 'sunca presata'              , isAllergen: false, isFrozen: false },
  { id: '4' , ingredient: 'salam'                      , isAllergen: false, isFrozen: false },
  { id: '5' , ingredient: 'masline'                    , isAllergen: false, isFrozen: false },
  { id: '6' , ingredient: 'ciuperci'                   , isAllergen: false, isFrozen: false },
  { id: '7' , ingredient: 'ardei gras'                 , isAllergen: false, isFrozen: false },
  { id: '8' , ingredient: 'bacon'                      , isAllergen: false, isFrozen: false },
  { id: '9' , ingredient: 'carne de pui'               , isAllergen: false, isFrozen: false },
  { id: '10', ingredient: 'rosii'                      , isAllergen: false, isFrozen: false },
  { id: '11', ingredient: 'carnaciori oltenesti'       , isAllergen: false, isFrozen: false },
  { id: '12', ingredient: 'ceapa'                      , isAllergen: false, isFrozen: false },
  { id: '13', ingredient: 'carnati afumati'            , isAllergen: false, isFrozen: false },
  { id: '14', ingredient: 'piept de pui'               , isAllergen: false, isFrozen: false },
  { id: '15', ingredient: 'salam picant'               , isAllergen: false, isFrozen: false },
  { id: '16', ingredient: 'ananas'                     , isAllergen: false, isFrozen: false },
  { id: '17', ingredient: 'ton'                        , isAllergen: true , isFrozen: false },
  { id: '18', ingredient: 'lamaie'                     , isAllergen: false, isFrozen: false },
  { id: '19', ingredient: 'cascaval'                   , isAllergen: true , isFrozen: false },
  { id: '20', ingredient: 'gorgonzola'                 , isAllergen: true , isFrozen: false },
  { id: '21', ingredient: 'parmezan'                   , isAllergen: true , isFrozen: false },
  { id: '22', ingredient: 'carne de vita tocata'       , isAllergen: false, isFrozen: false },
  { id: '23', ingredient: 'oregano'                    , isAllergen: false, isFrozen: false },
  { id: '24', ingredient: 'lipie'                      , isAllergen: true , isFrozen: true  },
  { id: '25', ingredient: 'salata de varza'            , isAllergen: false, isFrozen: false },
  { id: '26', ingredient: 'castraveti murati'          , isAllergen: false, isFrozen: false },
  { id: '27', ingredient: 'cartofi prajiti'            , isAllergen: false, isFrozen: false },
  { id: '28', ingredient: 'maioneza'                   , isAllergen: false, isFrozen: false },
  { id: '29', ingredient: 'ketchup'                    , isAllergen: false, isFrozen: false },
  { id: '30', ingredient: 'salata verde'               , isAllergen: false, isFrozen: false },
  { id: '31', ingredient: 'bacon crocant'              , isAllergen: false, isFrozen: false },
  { id: '32', ingredient: 'branza toast'               , isAllergen: true , isFrozen: false },
  { id: '33', ingredient: 'salam banatean'             , isAllergen: false, isFrozen: false },
  { id: '34', ingredient: 'piept de pui la gratar'     , isAllergen: false, isFrozen: false },
  { id: '35', ingredient: 'branza telemea'             , isAllergen: true , isFrozen: false },
  { id: '36', ingredient: 'oua'                        , isAllergen: true , isFrozen: false },
  { id: '37', ingredient: 'mustar'                     , isAllergen: false, isFrozen: false },
  { id: '38', ingredient: 'ceafa de porc'              , isAllergen: false, isFrozen: false },
  { id: '39', ingredient: 'pulpa de pui dezosata'      , isAllergen: false, isFrozen: false },
  { id: '40', ingredient: 'salata de rosii si varza'   , isAllergen: false, isFrozen: false },
  { id: '41', ingredient: 'o chifla'                   , isAllergen: true , isFrozen: true  },
  { id: '42', ingredient: 'ciocanele'                  , isAllergen: false, isFrozen: false },
  { id: '43', ingredient: 'crochete din piept de pui'  , isAllergen: false, isFrozen: false },
  { id: '44', ingredient: 'aripioare de pui crocante'  , isAllergen: false, isFrozen: false },
  { id: '45', ingredient: 'sos de tomate'              , isAllergen: false, isFrozen: false },
  { id: '46', ingredient: 'sos alb'                    , isAllergen: false, isFrozen: false },
  { id: '47', ingredient: 'chifla'                     , isAllergen: true , isFrozen: false },
  { id: '48', ingredient: 'macrou'                     , isAllergen: true , isFrozen: false },
  { id: '49', ingredient: 'pasta de usturoi'           , isAllergen: false, isFrozen: false },
  { id: '50', ingredient: 'sare'                       , isAllergen: false, isFrozen: false },
  { id: '51', ingredient: 'piper'                      , isAllergen: false, isFrozen: false },
  { id: '52', ingredient: 'otet'                       , isAllergen: false, isFrozen: false },
  { id: '53', ingredient: 'mamaliguta'                 , isAllergen: false, isFrozen: false },
  { id: '54', ingredient: 'mic'                        , isAllergen: false, isFrozen: false },
  { id: '55', ingredient: 'branza'                     , isAllergen: true , isFrozen: false },
  { id: '56', ingredient: 'carnaciori'                 , isAllergen: false, isFrozen: false },
  { id: '57', ingredient: 'carne de porc'              , isAllergen: false, isFrozen: false },
  { id: '58', ingredient: 'usturoi'                    , isAllergen: false, isFrozen: false },
  { id: '59', ingredient: 'cartofi natur'              , isAllergen: false, isFrozen: false },
  { id: '60', ingredient: 'branza feta'                , isAllergen: true , isFrozen: false },
  { id: '61', ingredient: 'ou'                         , isAllergen: true , isFrozen: false },
  { id: '62', ingredient: 'cartofi fierti'             , isAllergen: false, isFrozen: false },
  { id: '63', ingredient: 'castraveti'                 , isAllergen: false, isFrozen: false },
  { id: '64', ingredient: 'busuioc'                    , isAllergen: false, isFrozen: false },
  { id: '65', ingredient: 'porumb'                     , isAllergen: false, isFrozen: false },
  { id: '66', ingredient: 'ulei de masline'            , isAllergen: false, isFrozen: false },
  { id: '67', ingredient: 'smantana'                   , isAllergen: true , isFrozen: false },
  { id: '68', ingredient: 'costita'                    , isAllergen: false, isFrozen: false },
  { id: '69', ingredient: 'rigotta'                    , isAllergen: true , isFrozen: false },
  { id: '70', ingredient: 'carnacior'                  , isAllergen: false, isFrozen: false },
  { id: '71', ingredient: 'sunculita afumata'          , isAllergen: false, isFrozen: false },
  { id: '72', ingredient: 'sunculita ardeleneasca'     , isAllergen: false, isFrozen: false },
  { id: '73', ingredient: 'carnati'                    , isAllergen: false, isFrozen: false },
  { id: '74', ingredient: 'sunca'                      , isAllergen: false, isFrozen: false },
  { id: '75', ingredient: 'pulpe dezosate'             , isAllergen: false, isFrozen: false },
  { id: '76', ingredient: 'mici'                       , isAllergen: false, isFrozen: false },
  { id: '77', ingredient: 'crochete'                   , isAllergen: false, isFrozen: false },
  { id: '78', ingredient: 'inghetata'                  , isAllergen: true , isFrozen: false },
  { id: '79', ingredient: 'frisca'                     , isAllergen: false, isFrozen: false },
  { id: '80', ingredient: 'fructe'                     , isAllergen: false, isFrozen: false },
  { id: '81', ingredient: 'blat de pizza'              , isAllergen: true , isFrozen: true  },
  { id: '', ingredient: ''                           , isAllergen: false, isFrozen: false },
];
const measurementUnits = [
  { id: '1', measurementUnit: 'g'     , isSpaceRequired: false },
  { id: '2', measurementUnit: 'buc'   , isSpaceRequired: true  },
  { id: '3', measurementUnit: 'ml'    , isSpaceRequired: false },
  { id: '4', measurementUnit: 'portii', isSpaceRequired: true  },
  { id: '5', measurementUnit: 'cupe'  , isSpaceRequired: true  },
];
const products = [
  { id: '1'  , idCategory: '1' , product: 'Pizza Modern'                           , image: 'Pizza Modern.jpg'                           , position: 1 , description: '' },
  { id: '2'  , idCategory: '1' , product: 'Pizza rustica'                          , image: 'Pizza rustica.jpg'                          , position: 2 , description: '' },
  { id: '3'  , idCategory: '1' , product: 'Pizza vegetariana'                      , image: 'Pizza vegetariana.jpg'                      , position: 3 , description: '' },
  { id: '4'  , idCategory: '1' , product: 'Pizza afumata'                          , image: 'Pizza afumata.jpg'                          , position: 4 , description: '' },
  { id: '5'  , idCategory: '1' , product: 'Pizza cu pui'                           , image: 'Pizza cu pui.jpg'                           , position: 5 , description: '' },
  { id: '6'  , idCategory: '1' , product: 'Pizza diavolo'                          , image: 'Pizza diavolo.jpg'                          , position: 6 , description: '' },
  { id: '7'  , idCategory: '1' , product: 'Pizza margherita'                       , image: 'Pizza margherita.jpg'                       , position: 7 , description: '' },
  { id: '8'  , idCategory: '1' , product: 'Pizza Hawaii'                           , image: 'Pizza Hawaii.jpg'                           , position: 8 , description: '' },
  { id: '9'  , idCategory: '1' , product: 'Pizza funghi'                           , image: 'Pizza funghi.jpg'                           , position: 9 , description: '' },
  { id: '10' , idCategory: '1' , product: 'Pizza prosciuto'                        , image: 'Pizza prosciuto.jpg'                        , position: 10, description: '' },
  { id: '11' , idCategory: '1' , product: 'Pizza prosciuto e funghi'               , image: 'Pizza prosciuto e funghi.jpg'               , position: 11, description: '' },
  { id: '12' , idCategory: '1' , product: 'Pizza quatro stagioni'                  , image: 'Pizza quatro stagioni.jpg'                  , position: 12, description: '' },
  { id: '13' , idCategory: '1' , product: 'Pizza tonno'                            , image: 'Pizza tonno.jpg'                            , position: 13, description: '' },
  { id: '14' , idCategory: '1' , product: 'Pizza quatro formaggi'                  , image: 'Pizza quatro formaggi.jpg'                  , position: 14, description: '' },
  { id: '15' , idCategory: '1' , product: 'Pizza calzone'                          , image: 'Pizza calzone.jpg'                          , position: 15, description: '' },
  // { id: '16' , idCategory: '1' , product: 'Pizza bolognese'                        , image: 'Pizza bolognese.jpg'                        , position: 16, description: '' },
  { id: '17' , idCategory: '1' , product: 'Pizza salami'                           , image: 'Pizza salami.jpg'                           , position: 17, description: '' },
  { id: '18' , idCategory: '1' , product: 'Pizza sardegnia'                        , image: 'Pizza sardegnia.jpg'                        , position: 18, description: '' },
  { id: '19' , idCategory: '2' , product: 'Mozzarella'                             , image: ''                                           , position: 1 , description: '' },
  { id: '20' , idCategory: '2' , product: 'Sunca presata/salam/carnati'            , image: ''                                           , position: 2 , description: '' },
  { id: '21' , idCategory: '2' , product: 'Masline'                                , image: ''                                           , position: 3 , description: '' },
  { id: '22' , idCategory: '2' , product: 'Rosii'                                  , image: ''                                           , position: 4 , description: '' },
  { id: '23' , idCategory: '2' , product: 'Ceapa'                                  , image: ''                                           , position: 5 , description: '' },
  { id: '24' , idCategory: '2' , product: 'Ciuperci/porumb'                        , image: ''                                           , position: 6 , description: '' },
  { id: '25' , idCategory: '2' , product: 'Ardei kapia rosu'                            , image: ''                                           , position: 7 , description: '' },
  { id: '26' , idCategory: '2' , product: 'Parmezan/gorgonzola'                    , image: ''                                           , position: 8 , description: '' },
  { id: '27' , idCategory: '2' , product: 'Branza'                                 , image: ''                                           , position: 9 , description: '' },
  { id: '28' , idCategory: '2' , product: 'Extra sos (ketchup dulce sau picant)'   , image: ''                                           , position: 10, description: '' },
  { id: '29' , idCategory: '3' , product: 'Shaworma'                               , image: 'Shaworma.jpg'                               , position: 1 , description: '' },
  { id: '30' , idCategory: '4' , product: 'Sandwich cu ton'                        , image: 'Sandwich cu ton.jpg'                        , position: 1 , description: '' },
  { id: '31' , idCategory: '4' , product: 'Sandwich cu crispy bacon'               , image: 'Sandwich cu crispy bacon.jpg'               , position: 2 , description: '' },
  { id: '32' , idCategory: '4' , product: 'Sandwich cu sunca si salam'             , image: 'Sandwich cu sunca si salam.jpg'             , position: 3 , description: '' },
  { id: '33' , idCategory: '4' , product: 'Sandwich cu pui si castraveti murati'   , image: 'Sandwich cu pui si castraveti murati.jpg'   , position: 5 , description: '' },
  { id: '34' , idCategory: '4' , product: 'Sandwich cu pui si bacon'               , image: 'Sandwich cu pui si bacon.jpg'               , position: 6 , description: '' },
  { id: '35' , idCategory: '4' , product: 'Sandwich cu snitel de pui'              , image: 'Sandwich cu snitel de pui.jpg'              , position: 7 , description: '' },
  { id: '36' , idCategory: '4' , product: 'Sandwich vegetarian'                    , image: 'Sandwich vegetarian.jpg'                    , position: 8 , description: '' },
  // { id: '37' , idCategory: '4' , product: 'Hamburger'                              , image: 'Hamburger.jpg'                              , position: 9 , description: '' },
  { id: '38' , idCategory: '4' , product: 'Sandwich cu omleta cu sunca si cascaval', image: 'Sandwich cu omleta cu sunca si cascaval.jpg', position: 10, description: '' },
  { id: '39' , idCategory: '4' , product: 'Sandwich cu carnati oltenesti'          , image: 'Sandwich cu carnati oltenesti.jpg'          , position: 11, description: '' },
  { id: '40' , idCategory: '4' , product: 'Sandwich cu ceafa de porc'              , image: 'Sandwich cu ceafa de porc.jpg'              , position: 12, description: '' },
  { id: '41' , idCategory: '4' , product: 'Sandwich cu pulpa de pui'               , image: 'Sandwich cu pulpa de pui.jpg'               , position: 13, description: '' },
  { id: '42' , idCategory: '4' , product: 'Cartofi prajiti'                        , image: ''                                           , position: 14, description: '' },
  { id: '43' , idCategory: '4' , product: 'Cartofi natur'                          , image: ''                                           , position: 15, description: '' },
  { id: '44' , idCategory: '4' , product: 'Cascaval pane'                          , image: ''                                           , position: 16, description: '' },
  { id: '45' , idCategory: '4' , product: 'Focaccia simpla'                        , image: ''                                           , position: 17, description: '' },
  { id: '46' , idCategory: '4' , product: 'Focaccia cu usturoi'                    , image: ''                                           , position: 18, description: '' },
  { id: '47' , idCategory: '4' , product: 'Focaccia cu mozzarella'                 , image: ''                                           , position: 19, description: '' },
  { id: '48' , idCategory: '5' , product: 'Omleta'                                 , image: 'Omleta.jpg'                                 , position: 1 , description: '' },
  { id: '49' , idCategory: '5' , product: 'Ciocanele'                              , image: 'Ciocanele.jpg'                              , position: 2 , description: '' },
  { id: '50' , idCategory: '5' , product: 'Crochete'                               , image: 'Crochete.jpg'                               , position: 3 , description: '' },
  { id: '51' , idCategory: '5' , product: 'Piept de pui'                           , image: 'Piept de pui.jpg'                           , position: 4 , description: '' },
  { id: '52' , idCategory: '5' , product: 'Snitel de pui'                          , image: 'Snitel de pui.jpg'                          , position: 5 , description: '' },
  { id: '53' , idCategory: '5' , product: 'Ceafa de porc'                          , image: 'Ceafa de porc.jpg'                          , position: 6 , description: '' },
  { id: '54' , idCategory: '5' , product: 'Aripioare de pui'                       , image: 'Aripioare de pui.jpg'                       , position: 7 , description: '' },
  { id: '55' , idCategory: '5' , product: 'Pulpa la ceaun'                         , image: ''                                           , position: 8 , description: '' },
  { id: '56' , idCategory: '5' , product: 'Scalopina valdostana'                   , image: 'Scalopina valdostana.jpg'                   , position: 9 , description: '' },
  { id: '57' , idCategory: '5' , product: 'Scalopina funghi'                       , image: 'Scalopina funghi.jpg'                       , position: 10, description: '' },
  { id: '58' , idCategory: '5' , product: 'Pulpe dezosate'                         , image: 'Pulpe dezosate.jpg'                         , position: 11, description: '' },
  { id: '59' , idCategory: '5' , product: 'Kebab'                                  , image: 'Kebap.jpg'                                  , position: 12, description: '' },
  { id: '60' , idCategory: '5' , product: 'Rasol de macrou'                        , image: ''                                           , position: 13, description: '' },
  { id: '61' , idCategory: '5' , product: 'Saramura de macrou'                     , image: ''                                           , position: 14, description: '' },
  { id: '62' , idCategory: '5' , product: 'Tochitura'                              , image: ''                                           , position: 15, description: '' },
  { id: '63' , idCategory: '5' , product: 'Pomana porcului'                        , image: ''                                           , position: 16, description: '' },
  { id: '64' , idCategory: '5' , product: 'Mici'                                   , image: ''                                           , position: 17, description: 'Pretul este pe bucata' },
  { id: '65' , idCategory: '5' , product: 'Carnaciori la gratar'                   , image: ''                                           , position: 18, description: '' },
  { id: '66' , idCategory: '5' , product: 'Mamaliguta cu branza si smantana'       , image: ''                                           , position: 19, description: '' },
  { id: '67' , idCategory: '5' , product: 'Ciuperci sote'                          , image: ''                                           , position: 20, description: '' },
  { id: '68' , idCategory: '5' , product: 'Piure de cartofi'                       , image: ''                                           , position: 21, description: '' },
  { id: '69' , idCategory: '5' , product: 'Mamaliguta'                             , image: ''                                           , position: 22, description: '' },
  { id: '70' , idCategory: '5' , product: 'Ficatei la tigaie'                      , image: ''                                           , position: 23, description: '' },
  { id: '71' , idCategory: '5' , product: 'Oua ochiuri cu sunca si cascaval'       , image: ''                                           , position: 24, description: '' },
  { id: '72' , idCategory: '6' , product: 'Salata Modern'                          , image: 'Salata Modern.jpg'                          , position: 1 , description: '' },
  { id: '73' , idCategory: '6' , product: 'Salata cu ton'                          , image: 'Salata cu ton.jpg'                          , position: 2 , description: '' },
  { id: '74' , idCategory: '6' , product: 'Salata greceasca'                       , image: 'Salata greceasca.jpg'                       , position: 3 , description: '' },
  { id: '75' , idCategory: '6' , product: 'Salata primavera'                       , image: 'Salata primavera.jpg'                       , position: 4 , description: '' },
  { id: '76' , idCategory: '6' , product: 'Salata de rosii si castraveti'          , image: 'Salata de rosii cu branza si castraveti.jpg', position: 5 , description: '' },
  { id: '77' , idCategory: '6' , product: 'Salata de varza'                        , image: ''                                           , position: 6 , description: '' },
  { id: '78' , idCategory: '6' , product: 'Salata de castraveti'                   , image: ''                                           , position: 7 , description: '' },
  { id: '79' , idCategory: '6' , product: 'Salata de varza murata'                 , image: ''                                           , position: 8 , description: '' },
  { id: '80' , idCategory: '6' , product: 'Salata de muraturi'                     , image: ''                                           , position: 9 , description: '' },
  { id: '81' , idCategory: '6' , product: 'Salata asortata'                        , image: ''                                           , position: 10, description: '' },
  // { id: '82' , idCategory: '7' , product: 'Paste bolognese'                        , image: 'Paste bolognese.jpg'                        , position: 1 , description: '' },
  { id: '83' , idCategory: '7' , product: 'Paste carbonara'                        , image: 'Paste carbonara.jpg'                        , position: 2 , description: '' },
  { id: '84' , idCategory: '7' , product: 'Paste pomodoro'                         , image: 'Paste pomodoro.jpg'                         , position: 3 , description: '' },
  { id: '85' , idCategory: '7' , product: 'Paste penne al forno'                   , image: 'Paste penne al forno.jpg'                   , position: 4 , description: '' },
  { id: '86' , idCategory: '7' , product: 'Paste tonno'                            , image: 'Paste tonno.jpg'                            , position: 5 , description: '' },
  { id: '87' , idCategory: '7' , product: 'Paste matriciana'                       , image: 'Paste matriciana.jpg'                       , position: 6 , description: '' },
  { id: '88' , idCategory: '8' , product: 'Platou Modern'                          , image: ''                                           , position: 1 , description: '' },
  { id: '89' , idCategory: '8' , product: 'Platou rece'                            , image: ''                                           , position: 2 , description: '' },
  { id: '90' , idCategory: '8' , product: 'Platou rece festiv XXL'                 , image: ''                                           , position: 3 , description: '' },
  { id: '91' , idCategory: '8' , product: 'Platou festiv XXL'                      , image: ''                                           , position: 4 , description: '' },
  { id: '92' , idCategory: '9' , product: 'Clatite cu gem'                         , image: 'Clatite cu gem.jpg'                         , position: 1 , description: '' },
  { id: '93' , idCategory: '9' , product: 'Clatite cu finetti si frisca'           , image: 'Clatite cu finetti si frisca.jpg'           , position: 2 , description: '' },
  { id: '94' , idCategory: '9' , product: 'Salata de fructe cu frisca'             , image: ''                                           , position: 3 , description: '' },
  { id: '95' , idCategory: '9' , product: 'Papanasi cu frisca'                     , image: ''                                           , position: 4 , description: '' },
  { id: '96' , idCategory: '9' , product: 'Papanasi cu dulceata si smantana'       , image: ''                                           , position: 5 , description: '' },
  { id: '97' , idCategory: '9' , product: 'Inghetata'                              , image: ''                                           , position: 6 , description: '' },
  { id: '98' , idCategory: '10', product: 'Prigat'                                 , image: ''                                           , position: 6 , description: '' },
  // { id: '99' , idCategory: '10', product: 'Coca Cola'                              , image: ''                                           , position: 1 , description: '' },
  // { id: '100', idCategory: '10', product: 'Fanta'                                  , image: ''                                           , position: 2 , description: '' },
  { id: '101', idCategory: '10', product: 'Sprite'                                 , image: ''                                           , position: 3 , description: '' },
  { id: '102', idCategory: '10', product: 'Nestea'                                 , image: ''                                           , position: 7 , description: '' },
  // { id: '103', idCategory: '10', product: 'Apa plata/minerala'                     , image: ''                                           , position: 8 , description: '' },
  { id: '104', idCategory: '10', product: 'Red Bull'                               , image: ''                                           , position: 9 , description: '' },
  // { id: '105', idCategory: '11', product: 'Grolsch'                                , image: 'Grolsch 450ml.jpg'                          , position: 1 , description: '' },
  { id: '106', idCategory: '11', product: 'Peroni Nastro Azzurro'                  , image: ''                                           , position: 2 , description: '' },
  { id: '107', idCategory: '11', product: 'Ursus'                                  , image: 'Ursus 500ml.png'                            , position: 4 , description: '' },
  { id: '108', idCategory: '11', product: 'Ursus Cooler'                           , image: ''                                           , position: 5 , description: '' },
  { id: '109', idCategory: '11', product: 'Heineken'                               , image: 'Heineken 330ml.jpg'                         , position: 3 , description: '' },
  { id: '110', idCategory: '11', product: 'Ursus doza'                             , image: ''                                           , position: 6 , description: '' },
  { id: '111', idCategory: '11', product: 'Ursus brun'                             , image: ''                                           , position: 7 , description: '' },
  { id: '112', idCategory: '11', product: 'Timisoreana'                            , image: 'Timisoreana 500ml.jpg'                      , position: 8 , description: '' },
  { id: '113', idCategory: '11', product: 'Timisoreana doza'                       , image: 'Timisoreana doza 500ml.jpg'                 , position: 9 , description: '' },
  { id: '114', idCategory: '4' , product: 'Sandwich cu sunca si cascaval'          , image: 'Sandwich cu sunca si cascaval.jpg'          , position: 4 , description: '' },
  { id: '115', idCategory: '4' , product: 'Extra sos'                              , image: ''                                           , position: 20, description: '' },
  { id: '116', idCategory: '4' , product: 'Topping ou 1 buc'                       , image: ''                                           , position: 21, description: '' },
  { id: '117', idCategory: '4' , product: 'Lamaie'                                 , image: ''                                           , position: 22, description: '' },
  { id: '118', idCategory: '4' , product: 'Sunca slanina'                          , image: ''                                           , position: 23, description: '' },
  { id: '119', idCategory: '4' , product: 'Paine'                                  , image: ''                                           , position: 24, description: '' },
  // { id: '120', idCategory: '10', product: 'Schweppes'                              , image: ''                                           , position: 4 , description: '' },
  // { id: '121', idCategory: '10', product: 'Cappy'                                  , image: ''                                           , position: 5 , description: '' },
  { id: '122', idCategory: '11', product: 'Strongbow'                              , image: 'Strongbow 330ml.jpg'                        , position: 10, description: '' },
  { id: '123', idCategory: '11', product: 'Corona'                                 , image: 'Corona 330ml.png'                           , position: 11, description: '' },
  { id: '124', idCategory: '10', product: 'Coca Cola'                              , image: 'Coca Cola 500ml.jpg'                        , position: 1 , description: '' },
  { id: '125', idCategory: '10', product: 'Fanta'                                  , image: 'Fanta 500ml.jpg'                            , position: 2 , description: '' },
  { id: '126', idCategory: '10', product: 'Schweppes'                              , image: 'Schweppes 500ml.png'                        , position: 3 , description: '' },
  { id: '127', idCategory: '10', product: 'Cappy'                                  , image: 'Cappy 500ml.png'                            , position: 4 , description: '' },
  { id: '128', idCategory: '10', product: 'Apa plata/minerala'                     , image: ''                                           , position: 5 , description: '' },
  { id: '129', idCategory: '12', product: 'Busuioaca de Bohotin'                   , image: ''                                           , position: 1 , description: '' },
  { id: '130', idCategory: '12', product: 'Jidvei Feteasca Regala'                 , image: 'Jidvei Feteasca Regala.jpg'                 , position: 2 , description: '' },
  { id: '131', idCategory: '12', product: 'Jidvei Riesling'                        , image: 'Jidvei Riesling.jpg'                        , position: 3 , description: '' },
  { id: '132', idCategory: '12', product: 'Cotnari'                                , image: 'Cotnari.jpg'                                , position: 4 , description: '' },
  { id: '133', idCategory: '12', product: 'BD Feteasca neagra'                     , image: 'BD Feteasca neagra.jpg'                     , position: 5 , description: '' },
  { id: '134', idCategory: '12', product: 'Sampanie'                               , image: ''                                           , position: 6 , description: '' },
  { id: '135', idCategory: '12', product: 'Sampanie LUX'                           , image: ''                                           , position: 7 , description: '' },
  { id: '', idCategory: '', product: '', image: '', position: 0  },
];
const productIngredients = [
  { id: '1'  , idProduct: '1'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '2'  , idProduct: '1'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '3'  , idProduct: '1'  , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '4'  , idProduct: '1'  , idIngredient: '4' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '5'  , idProduct: '1'  , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '6'  , idProduct: '1'  , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '7'  , idProduct: '1'  , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '8'  , idProduct: '1'  , idIngredient: '8' , quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '9'  , idProduct: '1'  , idIngredient: '9' , quantity: ''   , idMeasurementUnit: '' , position: 10 },
  { id: '10' , idProduct: '2'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '11' , idProduct: '2'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '12' , idProduct: '2'  , idIngredient: '4' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '13' , idProduct: '2'  , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '14' , idProduct: '2'  , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '15' , idProduct: '2'  , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '16' , idProduct: '2'  , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '17' , idProduct: '2'  , idIngredient: '11', quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '18' , idProduct: '3'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '19' , idProduct: '3'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '20' , idProduct: '3'  , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '21' , idProduct: '3'  , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '22' , idProduct: '3'  , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '23' , idProduct: '3'  , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '24' , idProduct: '3'  , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '25' , idProduct: '4'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '26' , idProduct: '4'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '27' , idProduct: '4'  , idIngredient: '13', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '28' , idProduct: '4'  , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '29' , idProduct: '5'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '30' , idProduct: '5'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '31' , idProduct: '5'  , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '32' , idProduct: '5'  , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '33' , idProduct: '5'  , idIngredient: '14', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '34' , idProduct: '6'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '35' , idProduct: '6'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '36' , idProduct: '6'  , idIngredient: '15', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '37' , idProduct: '7'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '38' , idProduct: '7'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '39' , idProduct: '8'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '30' , idProduct: '8'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '41' , idProduct: '8'  , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '42' , idProduct: '8'  , idIngredient: '16', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '43' , idProduct: '9'  , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '44' , idProduct: '9'  , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '45' , idProduct: '9'  , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '46' , idProduct: '10' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '47' , idProduct: '10' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '48' , idProduct: '10' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '49' , idProduct: '11' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '50' , idProduct: '11' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '51' , idProduct: '11' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '52' , idProduct: '11' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '53' , idProduct: '12' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '54' , idProduct: '12' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '55' , idProduct: '12' , idIngredient: '4' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '56' , idProduct: '12' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '57' , idProduct: '12' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '58' , idProduct: '12' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '59' , idProduct: '12' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '60' , idProduct: '13' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '61' , idProduct: '13' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '62' , idProduct: '13' , idIngredient: '17', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '63' , idProduct: '13' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '64' , idProduct: '13' , idIngredient: '18', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '65' , idProduct: '14' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '66' , idProduct: '14' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '67' , idProduct: '14' , idIngredient: '19', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '68' , idProduct: '14' , idIngredient: '20', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '69' , idProduct: '14' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '70' , idProduct: '15' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '71' , idProduct: '15' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '72' , idProduct: '15' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '73' , idProduct: '15' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '74' , idProduct: '15' , idIngredient: '8' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '75' , idProduct: '16' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '76' , idProduct: '16' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '77' , idProduct: '16' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '78' , idProduct: '16' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '79' , idProduct: '16' , idIngredient: '22', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '80' , idProduct: '17' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '81' , idProduct: '17' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '82' , idProduct: '17' , idIngredient: '4' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '83' , idProduct: '17' , idIngredient: '23', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '84' , idProduct: '18' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '85' , idProduct: '18' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '86' , idProduct: '18' , idIngredient: '20', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '87' , idProduct: '18' , idIngredient: '8' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '88' , idProduct: '29' , idIngredient: '24', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '89' , idProduct: '29' , idIngredient: '9' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '90' , idProduct: '29' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '91' , idProduct: '29' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '92' , idProduct: '29' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '93' , idProduct: '29' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '94' , idProduct: '29' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '95' , idProduct: '29' , idIngredient: '29', quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '96' , idProduct: '29' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '97' , idProduct: '30' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '98' , idProduct: '30' , idIngredient: '17', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '99' , idProduct: '30' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '100', idProduct: '30' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '101', idProduct: '31' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '102', idProduct: '31' , idIngredient: '31', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '103', idProduct: '31' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '104', idProduct: '31' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '105', idProduct: '31' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '106', idProduct: '31' , idIngredient: '32', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '107', idProduct: '32' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '108', idProduct: '32' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '109', idProduct: '32' , idIngredient: '33', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '110', idProduct: '32' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '111', idProduct: '32' , idIngredient: '32', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '112', idProduct: '33' , idIngredient: '34', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '113', idProduct: '33' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '114', idProduct: '33' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '115', idProduct: '34' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '116', idProduct: '34' , idIngredient: '34', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '117', idProduct: '34' , idIngredient: '31', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '118', idProduct: '34' , idIngredient: '32', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '119', idProduct: '34' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '120', idProduct: '35' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '121', idProduct: '35' , idIngredient: '14', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '122', idProduct: '35' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '123', idProduct: '35' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '124', idProduct: '36' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '125', idProduct: '36' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '126', idProduct: '36' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '127', idProduct: '36' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '128', idProduct: '36' , idIngredient: '35', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '129', idProduct: '36' , idIngredient: '23', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '130', idProduct: '37' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '131', idProduct: '37' , idIngredient: '22', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '132', idProduct: '37' , idIngredient: '31', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '133', idProduct: '37' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '134', idProduct: '37' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '135', idProduct: '37' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '136', idProduct: '38' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '137', idProduct: '38' , idIngredient: '36', quantity: '2'  , idMeasurementUnit: '' , position: 2  },
  { id: '138', idProduct: '38' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '139', idProduct: '38' , idIngredient: '19', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '130', idProduct: '38' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '140', idProduct: '38' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '141', idProduct: '39' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '142', idProduct: '39' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '143', idProduct: '39' , idIngredient: '37', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '144', idProduct: '39' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '145', idProduct: '39' , idIngredient: '11', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '146', idProduct: '40' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '147', idProduct: '40' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '148', idProduct: '40' , idIngredient: '38', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '149', idProduct: '40' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '150', idProduct: '41' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '151', idProduct: '41' , idIngredient: '39', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '152', idProduct: '41' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '153', idProduct: '41' , idIngredient: '19', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '154', idProduct: '41' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '155', idProduct: '48' , idIngredient: '36', quantity: '2'  , idMeasurementUnit: '' , position: 1  },
  { id: '156', idProduct: '48' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '157', idProduct: '48' , idIngredient: '40', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '158', idProduct: '48' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '159', idProduct: '48' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '160', idProduct: '49' , idIngredient: '42', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '161', idProduct: '49' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '162', idProduct: '49' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '163', idProduct: '49' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '164', idProduct: '50' , idIngredient: '43', quantity: '4'  , idMeasurementUnit: '' , position: 1  },
  { id: '165', idProduct: '50' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '166', idProduct: '50' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '167', idProduct: '50' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '168', idProduct: '50' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '169', idProduct: '51' , idIngredient: '34', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '170', idProduct: '51' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '171', idProduct: '51' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '172', idProduct: '51' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '173', idProduct: '51' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '174', idProduct: '52' , idIngredient: '14', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '175', idProduct: '52' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '176', idProduct: '52' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '177', idProduct: '52' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '178', idProduct: '52' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '179', idProduct: '53' , idIngredient: '38', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '180', idProduct: '53' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '181', idProduct: '53' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '182', idProduct: '53' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '183', idProduct: '53' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '184', idProduct: '54' , idIngredient: '44', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '185', idProduct: '54' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '186', idProduct: '54' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '187', idProduct: '54' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '188', idProduct: '54' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '189', idProduct: '56' , idIngredient: '14', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '190', idProduct: '56' , idIngredient: '59', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '191', idProduct: '56' , idIngredient: '45', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '192', idProduct: '56' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '193', idProduct: '56' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '194', idProduct: '57' , idIngredient: '14', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '195', idProduct: '57' , idIngredient: '59', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '196', idProduct: '57' , idIngredient: '46', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '197', idProduct: '57' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '198', idProduct: '57' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '199', idProduct: '58' , idIngredient: '39', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '200', idProduct: '58' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '201', idProduct: '58' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '202', idProduct: '58' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '203', idProduct: '59' , idIngredient: '47', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '204', idProduct: '59' , idIngredient: '9' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '205', idProduct: '59' , idIngredient: '25', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '206', idProduct: '59' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '207', idProduct: '59' , idIngredient: '26', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '208', idProduct: '59' , idIngredient: '27', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '209', idProduct: '59' , idIngredient: '28', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '210', idProduct: '59' , idIngredient: '29', quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '211', idProduct: '60' , idIngredient: '48', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '212', idProduct: '60' , idIngredient: '59', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '213', idProduct: '60' , idIngredient: '49', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '214', idProduct: '60' , idIngredient: '18', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '215', idProduct: '61' , idIngredient: '48', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '216', idProduct: '61' , idIngredient: '50', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '217', idProduct: '61' , idIngredient: '51', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '218', idProduct: '61' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '219', idProduct: '61' , idIngredient: '52', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '220', idProduct: '62' , idIngredient: '53', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '221', idProduct: '62' , idIngredient: '36', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '222', idProduct: '62' , idIngredient: '55', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '223', idProduct: '62' , idIngredient: '56', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '224', idProduct: '62' , idIngredient: '57', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '225', idProduct: '63' , idIngredient: '57', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '226', idProduct: '63' , idIngredient: '56', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '227', idProduct: '63' , idIngredient: '58', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '228', idProduct: '63' , idIngredient: '53', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '229', idProduct: '63' , idIngredient: '36', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '230', idProduct: '63' , idIngredient: '55', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '231', idProduct: '72' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '232', idProduct: '72' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '233', idProduct: '72' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '234', idProduct: '72' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '235', idProduct: '72' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '236', idProduct: '72' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '237', idProduct: '72' , idIngredient: '60', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '238', idProduct: '72' , idIngredient: '9' , quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '239', idProduct: '72' , idIngredient: '61', quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '240', idProduct: '72' , idIngredient: '62', quantity: ''   , idMeasurementUnit: '' , position: 10 },
  { id: '241', idProduct: '72' , idIngredient: '63', quantity: ''   , idMeasurementUnit: '' , position: 11 },
  { id: '242', idProduct: '73' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '243', idProduct: '73' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '244', idProduct: '73' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '245', idProduct: '73' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '246', idProduct: '73' , idIngredient: '17', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '247', idProduct: '73' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '248', idProduct: '74' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '249', idProduct: '74' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '250', idProduct: '74' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '251', idProduct: '74' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '252', idProduct: '74' , idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '253', idProduct: '74' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '254', idProduct: '74' , idIngredient: '55', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '255', idProduct: '74' , idIngredient: '64', quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '256', idProduct: '74' , idIngredient: '23', quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '257', idProduct: '75' , idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '258', idProduct: '75' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '259', idProduct: '75' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '260', idProduct: '75' , idIngredient: '5' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '261', idProduct: '75' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '262', idProduct: '75' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '263', idProduct: '75' , idIngredient: '65', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '264', idProduct: '81' , idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '265', idProduct: '81' , idIngredient: '63', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '266', idProduct: '81' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '267', idProduct: '81' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '268', idProduct: '82' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '269', idProduct: '82' , idIngredient: '22', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '270', idProduct: '82' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '271', idProduct: '82' , idIngredient: '66', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '272', idProduct: '83' , idIngredient: '66', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '273', idProduct: '83' , idIngredient: '67', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '274', idProduct: '83' , idIngredient: '68', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '275', idProduct: '83' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '276', idProduct: '83' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '277', idProduct: '83' , idIngredient: '61', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '278', idProduct: '84' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '279', idProduct: '84' , idIngredient: '66', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '280', idProduct: '84' , idIngredient: '58', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '281', idProduct: '84' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '282', idProduct: '84' , idIngredient: '64', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '283', idProduct: '85' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '284', idProduct: '85' , idIngredient: '2' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '285', idProduct: '85' , idIngredient: '69', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '286', idProduct: '85' , idIngredient: '6' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '287', idProduct: '86' , idIngredient: '66', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '288', idProduct: '86' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '289', idProduct: '86' , idIngredient: '58', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '290', idProduct: '86' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '291', idProduct: '86' , idIngredient: '18', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '292', idProduct: '86' , idIngredient: '17', quantity: ''   , idMeasurementUnit: '' , position: 6  },
  { id: '293', idProduct: '87' , idIngredient: '1' , quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '294', idProduct: '87' , idIngredient: '12', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '295', idProduct: '87' , idIngredient: '68', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '296', idProduct: '87' , idIngredient: '21', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '297', idProduct: '87' , idIngredient: '66', quantity: ''   , idMeasurementUnit: '' , position: 5  },
  { id: '298', idProduct: '88' , idIngredient: '14', quantity: '100', idMeasurementUnit: '1', position: 1  },
  { id: '299', idProduct: '88' , idIngredient: '38', quantity: '100', idMeasurementUnit: '1', position: 2  },
  { id: '300', idProduct: '88' , idIngredient: '54', quantity: '1'  , idMeasurementUnit: '' , position: 3  },
  { id: '301', idProduct: '88' , idIngredient: '70', quantity: '1'  , idMeasurementUnit: '' , position: 4  },
  { id: '302', idProduct: '88' , idIngredient: '27', quantity: '100', idMeasurementUnit: '1', position: 5  },
  { id: '303', idProduct: '88' , idIngredient: '71', quantity: '50' , idMeasurementUnit: '1', position: 6  },
  { id: '304', idProduct: '88' , idIngredient: '55', quantity: '30' , idMeasurementUnit: '1', position: 7  },
  { id: '305', idProduct: '88' , idIngredient: '37', quantity: '20' , idMeasurementUnit: '1', position: 8  },
  { id: '306', idProduct: '88' , idIngredient: '10', quantity: '50' , idMeasurementUnit: '1', position: 9  },
  { id: '307', idProduct: '88' , idIngredient: '63', quantity: ''   , idMeasurementUnit: '' , position: 10 },
  { id: '308', idProduct: '88' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 11 },
  { id: '309', idProduct: '88' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 12 },
  { id: '310', idProduct: '89' , idIngredient: '19', quantity: '30' , idMeasurementUnit: '1', position: 1  },
  { id: '311', idProduct: '89' , idIngredient: '3' , quantity: '30' , idMeasurementUnit: '1', position: 2  },
  { id: '312', idProduct: '89' , idIngredient: '4' , quantity: '30' , idMeasurementUnit: '1', position: 3  },
  { id: '313', idProduct: '89' , idIngredient: '5' , quantity: '30' , idMeasurementUnit: '1', position: 4  },
  { id: '314', idProduct: '89' , idIngredient: '72', quantity: '30' , idMeasurementUnit: '1', position: 5  },
  { id: '315', idProduct: '89' , idIngredient: '10', quantity: '50' , idMeasurementUnit: '1', position: 6  },
  { id: '316', idProduct: '89' , idIngredient: '63', quantity: ''   , idMeasurementUnit: '' , position: 7  },
  { id: '317', idProduct: '89' , idIngredient: '7' , quantity: ''   , idMeasurementUnit: '' , position: 8  },
  { id: '318', idProduct: '89' , idIngredient: '41', quantity: ''   , idMeasurementUnit: '' , position: 9  },
  { id: '319', idProduct: '90' , idIngredient: '73', quantity: '500', idMeasurementUnit: '1', position: 1  },
  { id: '320', idProduct: '90' , idIngredient: '5' , quantity: '500', idMeasurementUnit: '1', position: 2  },
  { id: '321', idProduct: '90' , idIngredient: '19', quantity: '300', idMeasurementUnit: '1', position: 3  },
  { id: '322', idProduct: '90' , idIngredient: '4' , quantity: '300', idMeasurementUnit: '1', position: 4  },
  { id: '323', idProduct: '90' , idIngredient: '74', quantity: '300', idMeasurementUnit: '1', position: 5  },
  { id: '324', idProduct: '90' , idIngredient: '3' , quantity: '300', idMeasurementUnit: '1', position: 6  },
  { id: '325', idProduct: '90' , idIngredient: '63', quantity: '250', idMeasurementUnit: '1', position: 7  },
  { id: '326', idProduct: '90' , idIngredient: '7' , quantity: '100', idMeasurementUnit: '1', position: 8  },
  { id: '327', idProduct: '90' , idIngredient: '10', quantity: '100', idMeasurementUnit: '1', position: 9  },
  { id: '328', idProduct: '91' , idIngredient: '73', quantity: '800', idMeasurementUnit: '1', position: 1  },
  { id: '329', idProduct: '91' , idIngredient: '38', quantity: '800', idMeasurementUnit: '1', position: 2  },
  { id: '330', idProduct: '91' , idIngredient: '42', quantity: '800', idMeasurementUnit: '1', position: 3  },
  { id: '331', idProduct: '91' , idIngredient: '14', quantity: '800', idMeasurementUnit: '1', position: 4  },
  { id: '332', idProduct: '91' , idIngredient: '75', quantity: '800', idMeasurementUnit: '1', position: 5  },
  { id: '333', idProduct: '91' , idIngredient: '76', quantity: '480', idMeasurementUnit: '1', position: 6  },
  { id: '334', idProduct: '91' , idIngredient: '63', quantity: '250', idMeasurementUnit: '1', position: 7  },
  { id: '335', idProduct: '91' , idIngredient: '77', quantity: '6'  , idMeasurementUnit: '4', position: 8  },
  { id: '336', idProduct: '97' , idIngredient: '78', quantity: '3'  , idMeasurementUnit: '5', position: 1  },
  { id: '337', idProduct: '97' , idIngredient: '79', quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '338', idProduct: '97' , idIngredient: '80', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '339', idProduct: '114', idIngredient: '30', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '340', idProduct: '114', idIngredient: '3' , quantity: ''   , idMeasurementUnit: '' , position: 2  },
  { id: '341', idProduct: '114', idIngredient: '10', quantity: ''   , idMeasurementUnit: '' , position: 3  },
  { id: '342', idProduct: '114', idIngredient: '32', quantity: ''   , idMeasurementUnit: '' , position: 4  },
  { id: '343', idProduct: '1'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '344', idProduct: '2'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '345', idProduct: '3'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '346', idProduct: '4'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '347', idProduct: '5'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '348', idProduct: '6'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '349', idProduct: '7'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '350', idProduct: '8'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '351', idProduct: '9'  , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '352', idProduct: '10' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '353', idProduct: '11' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '354', idProduct: '12' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '355', idProduct: '13' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '356', idProduct: '14' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '357', idProduct: '15' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '358', idProduct: '16' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '359', idProduct: '17' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '360', idProduct: '18' , idIngredient: '81', quantity: ''   , idMeasurementUnit: '' , position: 1  },
  { id: '', idProduct: '', idIngredient: '', position: 0 },
];
const productVariants = [
  { id: '1'  , idProduct: '1'  , idVariant: '1', quantity: '850' , idMeasurementUnit: '1', price: '50'  }, // Pizza Modern medie
  { id: '2'  , idProduct: '1'  , idVariant: '2', quantity: '1670', idMeasurementUnit: '1', price: '85'  }, // Pizza Modern XXL
  { id: '3'  , idProduct: '2'  , idVariant: '1', quantity: '780' , idMeasurementUnit: '1', price: '50'  }, // Pizza rustica medie
  { id: '4'  , idProduct: '2'  , idVariant: '2', quantity: '1510', idMeasurementUnit: '1', price: '85'  }, // Pizza rustica XXL
  { id: '5'  , idProduct: '3'  , idVariant: '1', quantity: '770' , idMeasurementUnit: '1', price: '45'  }, // Pizza vegetariana medie
  { id: '6'  , idProduct: '3'  , idVariant: '2', quantity: '1460', idMeasurementUnit: '1', price: '70'  }, // Pizza vegetariana XXL
  { id: '7'  , idProduct: '4'  , idVariant: '1', quantity: '740' , idMeasurementUnit: '1', price: '45'  }, // Pizza afumata medie
  { id: '8'  , idProduct: '4'  , idVariant: '2', quantity: '1420', idMeasurementUnit: '1', price: '80'  }, // Pizza afumata XXL
  { id: '9'  , idProduct: '5'  , idVariant: '1', quantity: '680' , idMeasurementUnit: '1', price: '45'  }, // Pizza cu pui medie
  { id: '10' , idProduct: '5'  , idVariant: '2', quantity: '1270', idMeasurementUnit: '1', price: '80'  }, // Pizza cu pui XXL
  { id: '11' , idProduct: '6'  , idVariant: '1', quantity: '625' , idMeasurementUnit: '1', price: '45'  }, // Pizza diavolo medie
  { id: '12' , idProduct: '6'  , idVariant: '2', quantity: '1200', idMeasurementUnit: '1', price: '80'  }, // Pizza diavolo XXL
  { id: '13' , idProduct: '7'  , idVariant: '1', quantity: '630' , idMeasurementUnit: '1', price: '40'  }, // Pizza margherita medie
  { id: '14' , idProduct: '7'  , idVariant: '2', quantity: '1120', idMeasurementUnit: '1', price: '65'  }, // Pizza margherita XXL
  { id: '15' , idProduct: '8'  , idVariant: '1', quantity: '770' , idMeasurementUnit: '1', price: '45'  }, // Pizza Hawaii medie
  { id: '16' , idProduct: '8'  , idVariant: '2', quantity: '1460', idMeasurementUnit: '1', price: '80'  }, // Pizza Hawaii XXL
  { id: '17' , idProduct: '9'  , idVariant: '1', quantity: '640' , idMeasurementUnit: '1', price: '40'  }, // Pizza funghi medie
  { id: '18' , idProduct: '9'  , idVariant: '2', quantity: '1230', idMeasurementUnit: '1', price: '65'  }, // Pizza funghi XXL
  { id: '19' , idProduct: '10' , idVariant: '1', quantity: '620' , idMeasurementUnit: '1', price: '45'  }, // Pizza prosciuto medie
  { id: '20' , idProduct: '10' , idVariant: '2', quantity: '1190', idMeasurementUnit: '1', price: '80'  }, // Pizza prosciuto XXL
  { id: '21' , idProduct: '11' , idVariant: '1', quantity: '660' , idMeasurementUnit: '1', price: '45'  }, // Pizza prosciuto e funghi medie
  { id: '22' , idProduct: '11' , idVariant: '2', quantity: '1280', idMeasurementUnit: '1', price: '80'  }, // Pizza prosciuto e funghi XXL
  { id: '23' , idProduct: '12' , idVariant: '1', quantity: '745' , idMeasurementUnit: '1', price: '50'  }, // Pizza quatro stagioni medie
  { id: '24' , idProduct: '12' , idVariant: '2', quantity: '1440', idMeasurementUnit: '1', price: '85'  }, // Pizza quatro stagioni XXL
  { id: '25' , idProduct: '13' , idVariant: '1', quantity: '740' , idMeasurementUnit: '1', price: '45'  }, // Pizza tonno medie
  { id: '26' , idProduct: '13' , idVariant: '2', quantity: '1370', idMeasurementUnit: '1', price: '80'  }, // Pizza tonno XXL
  { id: '27' , idProduct: '14' , idVariant: '1', quantity: '620' , idMeasurementUnit: '1', price: '45'  }, // Pizza quatro formaggi medie
  { id: '28' , idProduct: '14' , idVariant: '2', quantity: '1210', idMeasurementUnit: '1', price: '75'  }, // Pizza quatro formaggi XXL
  { id: '29' , idProduct: '15' , idVariant: '1', quantity: '670' , idMeasurementUnit: '1', price: '55'  }, // Pizza calzone medie
  // { id: '30' , idProduct: '16' , idVariant: '1', quantity: '690' , idMeasurementUnit: '1', price: '40'  }, //
  // { id: '31' , idProduct: '16' , idVariant: '2', quantity: '1290', idMeasurementUnit: '1', price: '70'  }, //
  { id: '32' , idProduct: '17' , idVariant: '1', quantity: '600' , idMeasurementUnit: '1', price: '45'  }, // Pizza salami medie
  { id: '33' , idProduct: '17' , idVariant: '2', quantity: '1160', idMeasurementUnit: '1', price: '80'  }, // Pizza salami XXL
  { id: '34' , idProduct: '18' , idVariant: '1', quantity: '620' , idMeasurementUnit: '1', price: '50'  }, // Pizza sardegnia medie
  { id: '35' , idProduct: '18' , idVariant: '2', quantity: '1150', idMeasurementUnit: '1', price: '85'  }, // Pizza sardegnia XXL
  { id: '36' , idProduct: '19' , idVariant: '' , quantity: '80'  , idMeasurementUnit: '1', price: '20'  }, // Mozzarella
  { id: '37' , idProduct: '20' , idVariant: '' , quantity: '100' , idMeasurementUnit: '1', price: '15'  }, // Sunca presata/salam/carnati
  { id: '38' , idProduct: '21' , idVariant: '' , quantity: '50'  , idMeasurementUnit: '1', price: '10'  }, // Masline
  { id: '39' , idProduct: '22' , idVariant: '' , quantity: '100' , idMeasurementUnit: '1', price: '8'   }, // Rosii
  { id: '40' , idProduct: '23' , idVariant: '' , quantity: '50'  , idMeasurementUnit: '1', price: '5'   }, // Ceapa
  { id: '41' , idProduct: '24' , idVariant: '' , quantity: '50'  , idMeasurementUnit: '1', price: '10'  }, // Ciuperci/porumb
  { id: '42' , idProduct: '25' , idVariant: '' , quantity: '50'  , idMeasurementUnit: '1', price: '8'   }, // Ardei kapia rosu
  { id: '43' , idProduct: '26' , idVariant: '' , quantity: '50'  , idMeasurementUnit: '1', price: '15'  }, // Parmezan/gorgonzola
  { id: '44' , idProduct: '27' , idVariant: '' , quantity: '80'  , idMeasurementUnit: '1', price: '15'  }, // Branza
  { id: '45' , idProduct: '28' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '6'   }, // Extra sos (ketchup dulce sau picant)
  { id: '46' , idProduct: '29' , idVariant: '3', quantity: ''    , idMeasurementUnit: '' , price: '30'  }, // Shaworma mica
  { id: '47' , idProduct: '29' , idVariant: '4', quantity: ''    , idMeasurementUnit: '' , price: '40'  }, // Shaworma mare
  { id: '48' , idProduct: '29' , idVariant: '5', quantity: ''    , idMeasurementUnit: '' , price: '50'  }, // Shaworma farfurie
  { id: '49' , idProduct: '30' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '25'  }, // Sandwich cu ton
  { id: '50' , idProduct: '31' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '30'  }, // Sandwich cu crispy bacon
  { id: '51' , idProduct: '32' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '25'  }, // Sandwich cu sunca si salam
  { id: '52' , idProduct: '33' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '30'  }, // Sandwich cu pui si castraveti murati
  { id: '53' , idProduct: '34' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '30'  }, // Sandwich cu pui si bacon
  { id: '54' , idProduct: '35' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '35'  }, // Sandwich cu snitel de pui
  { id: '55' , idProduct: '36' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '25'  }, // Sandwich vegetarian
  // { id: '56' , idProduct: '37' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '30'  }, //
  { id: '57' , idProduct: '38' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '30'  }, // Sandwich cu omleta cu sunca si cascaval
  { id: '58' , idProduct: '39' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '35'  }, // Sandwich cu carnati oltenesti
  { id: '59' , idProduct: '40' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '35'  }, // Sandwich cu ceafa de porc
  { id: '60' , idProduct: '41' , idVariant: '' , quantity: '350' , idMeasurementUnit: '1', price: '35'  }, // Sandwich cu pulpa de pui
  { id: '61' , idProduct: '42' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '15'  }, // Cartofi prajiti
  { id: '62' , idProduct: '43' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '15'  }, // Cartofi natur
  { id: '63' , idProduct: '44' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '40'  }, // Cascaval pane
  { id: '64' , idProduct: '45' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '15'  }, // Focaccia simpla
  { id: '65' , idProduct: '46' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '20'  }, // Focaccia cu usturoi
  { id: '66' , idProduct: '47' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '30'  }, // Focaccia cu mozzarella
  { id: '67' , idProduct: '48' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '35'  }, // Omleta
  { id: '68' , idProduct: '49' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Ciocanele
  { id: '69' , idProduct: '50' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Crochete
  { id: '70' , idProduct: '51' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Piept de pui
  { id: '71' , idProduct: '52' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Snitel de pui
  { id: '72' , idProduct: '53' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Ceafa de porc
  { id: '73' , idProduct: '54' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Aripioare de pui
  { id: '74' , idProduct: '55' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '35'  }, // Pulpa la ceaun
  { id: '75' , idProduct: '56' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Scalopina valdostana
  { id: '76' , idProduct: '57' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '65'  }, // Scalopina funghi
  { id: '77' , idProduct: '58' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Pulpe dezosate
  { id: '78' , idProduct: '59' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '30'  }, // Kebab
  { id: '79' , idProduct: '60' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Rasol de macrou
  { id: '80' , idProduct: '61' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Saramura de macrou
  { id: '81' , idProduct: '62' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Tochitura
  { id: '82' , idProduct: '63' , idVariant: '' , quantity: '500' , idMeasurementUnit: '1', price: '50'  }, // Pomana porcului
  { id: '83' , idProduct: '64' , idVariant: '' , quantity: '60'  , idMeasurementUnit: '1', price: '5'   }, // Mici
  { id: '84' , idProduct: '65' , idVariant: '' , quantity: '4'   , idMeasurementUnit: '2', price: '35'  }, // Carnaciori la gratar
  { id: '85' , idProduct: '66' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '40'  }, // Mamaliguta cu branza si smantana
  { id: '86' , idProduct: '67' , idVariant: '' , quantity: '250' , idMeasurementUnit: '1', price: '25'  }, // Ciuperci sote
  { id: '87' , idProduct: '68' , idVariant: '' , quantity: '250' , idMeasurementUnit: '1', price: '15'  }, // Piure de cartofi
  { id: '88' , idProduct: '69' , idVariant: '' , quantity: '250' , idMeasurementUnit: '1', price: '10'  }, // Mamaliguta
  { id: '89' , idProduct: '70' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '30'  }, // Ficatei la tigaie
  { id: '90' , idProduct: '71' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '25'  }, // Oua ochiuri cu sunca si cascaval
  { id: '91' , idProduct: '72' , idVariant: '' , quantity: '400' , idMeasurementUnit: '1', price: '50'  }, // Salata Modern
  { id: '92' , idProduct: '73' , idVariant: '' , quantity: '400' , idMeasurementUnit: '1', price: '45'  }, // Salata cu ton
  { id: '93' , idProduct: '74' , idVariant: '' , quantity: '400' , idMeasurementUnit: '1', price: '45'  }, // Salata greceasca
  { id: '94' , idProduct: '75' , idVariant: '' , quantity: '400' , idMeasurementUnit: '1', price: '40'  }, // Salata primavera
  { id: '95' , idProduct: '76' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '15'  }, // Salata de rosii si castraveti
  { id: '96' , idProduct: '77' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '10'  }, // Salata de varza
  { id: '97' , idProduct: '78' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '10'  }, // Salata de castraveti
  { id: '98' , idProduct: '79' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '10'  }, // Salata de varza murata
  { id: '99' , idProduct: '80' , idVariant: '' , quantity: '150' , idMeasurementUnit: '1', price: '10'  }, // Salata de muraturi
  { id: '100', idProduct: '81' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '20'  }, // Salata asortata
  // { id: '101', idProduct: '82' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '40'  }, // Paste bolognese
  { id: '102', idProduct: '83' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '55'  }, // Paste carbonara
  { id: '103', idProduct: '84' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '40'  }, // Paste pomodoro
  { id: '104', idProduct: '85' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '45'  }, // Paste penne al forno
  { id: '105', idProduct: '86' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '45'  }, // Paste tonno
  { id: '106', idProduct: '87' , idVariant: '' , quantity: '300' , idMeasurementUnit: '1', price: '50'  }, // Paste matriciana
  { id: '107', idProduct: '88' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '75'  }, // Platou Modern
  { id: '108', idProduct: '89' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '50'  }, // Platou rece
  { id: '109', idProduct: '90' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '350' }, // Platou rece festiv XXL
  { id: '110', idProduct: '91' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '500' }, // Platou festiv XXL
  { id: '111', idProduct: '92' , idVariant: '' , quantity: '2'   , idMeasurementUnit: '2', price: '25'  }, // Clatite cu gem
  { id: '112', idProduct: '93' , idVariant: '' , quantity: '2'   , idMeasurementUnit: '2', price: '30'  }, // Clatite cu finetti si frisca
  { id: '113', idProduct: '94' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '30'  }, // Salata de fructe cu frisca
  { id: '114', idProduct: '95' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '35'  }, // Papanasi cu frisca
  { id: '115', idProduct: '96' , idVariant: '' , quantity: '200' , idMeasurementUnit: '1', price: '35'  }, // Papanasi cu dulceata si smantana
  { id: '116', idProduct: '97' , idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '35'  }, // Inghetata
  { id: '117', idProduct: '98' , idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Prigat 250ml
  // { id: '118', idProduct: '99' , idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '10'  },
  // { id: '119', idProduct: '100', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '10'  },
  { id: '120', idProduct: '101', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Sprite 250ml
  { id: '121', idProduct: '102', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'   }, // Nestea 250ml
  // { id: '122', idProduct: '103', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '6'   },
  { id: '123', idProduct: '104', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '15'  }, // Red Bull
  // { id: '124', idProduct: '105', idVariant: '' , quantity: '450' , idMeasurementUnit: '3', price: '20'  },
  { id: '125', idProduct: '106', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '15'  }, // Peroni Nastro Azzurro 330ml
  { id: '126', idProduct: '107', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '15'  }, // Ursus 330ml
  { id: '127', idProduct: '108', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '15'  }, // Ursus Cooler 330ml
  { id: '128', idProduct: '109', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '15'  }, // Heineken 330ml
  { id: '129', idProduct: '110', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Ursus doza 500ml
  { id: '130', idProduct: '111', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Ursus brun 500ml
  { id: '131', idProduct: '112', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Timisoreana 500ml
  { id: '132', idProduct: '113', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Timisoreana doza 500ml
  { id: '133', idProduct: '114', idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '25'  }, // Sandwich cu sunca si cascaval
  { id: '134', idProduct: '115', idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '6'   }, // Extra sos
  { id: '135', idProduct: '116', idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '5'   }, // Topping ou 1 buc
  { id: '136', idProduct: '117', idVariant: '' , quantity: '100' , idMeasurementUnit: '1', price: '5'   }, // Lamaie
  { id: '137', idProduct: '118', idVariant: '' , quantity: '100' , idMeasurementUnit: '1', price: '20'  }, // Sunca slanina
  { id: '138', idProduct: '119', idVariant: '' , quantity: ''    , idMeasurementUnit: '' , price: '4'   }, // Paine
  // { id: '139', idProduct: '120', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '10'  },
  // { id: '140', idProduct: '121', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '10'  },
  { id: '141', idProduct: '108', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Ursus Cooler 500ml
  { id: '142', idProduct: '122', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '15'  }, // Strongbow 330ml
  { id: '143', idProduct: '123', idVariant: '' , quantity: '330' , idMeasurementUnit: '3', price: '20'  }, // Corona 330ml
  { id: '144', idProduct: '124', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Coca Cola 250ml
  { id: '145', idProduct: '125', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Fanta 250ml
  { id: '146', idProduct: '126', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Schweppes 250ml
  { id: '147', idProduct: '127', idVariant: '' , quantity: '250' , idMeasurementUnit: '3', price: '12'  }, // Cappy 250ml
  { id: '148', idProduct: '128', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '10'  }, // Apa plata/minerala 330ml
  { id: '149', idProduct: '129', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '60'  }, // Busuioaca de Bohotin
  { id: '150', idProduct: '130', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '60'  }, // Jidvei Feteasca Regala
  { id: '151', idProduct: '131', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '60'  }, // Jidvei Riesling
  { id: '152', idProduct: '132', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '60'  }, // Cotnari
  { id: '153', idProduct: '133', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '70'  }, // BD Feteasca neagra
  { id: '154', idProduct: '134', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '100' }, // Sampanie
  { id: '155', idProduct: '135', idVariant: '' , quantity: '750' , idMeasurementUnit: '3', price: '200' }, // Sampanie LUX
  { id: '156', idProduct: '124', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Coca Cola 500ml
  { id: '157', idProduct: '125', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Fanta 500ml
  { id: '158', idProduct: '126', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Schweppes 500ml
  { id: '159', idProduct: '127', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '15'  }, // Cappy 500ml
  { id: '160', idProduct: '128', idVariant: '' , quantity: '500' , idMeasurementUnit: '3', price: '10'  }, // Apa plata/minerala 500ml
  { id: '', idProduct: '', idVariant: '', quantity: '', idMeasurementUnit: '', price: '' },
];
const variants = [
  { id: '1' , idCategory: '1', variant: 'Medie 32cm' , position: 1 },
  { id: '2' , idCategory: '1', variant: 'XXL 45cm'   , position: 2 },
  { id: '3' , idCategory: '3', variant: 'Mica'       , position: 1 },
  { id: '4' , idCategory: '3', variant: 'Mare'       , position: 2 },
  { id: '5' , idCategory: '3', variant: 'La farfurie', position: 3 },
];

const App = props => {
  /**
   * States
   */
  const [data, setData] = useState({
    cart: []
  });
  const [deps, setDeps] = useState(null);
  const [view, setView] = useState('Menu');
  const [idCategory, setIdCategory] = useState(null);

  /**
   * Hooks
   */
  useEffect(() => {
    setDeps({
      _: jsSqlMeta,
      categories: categories,
      ingredients: ingredients,
      measurementUnits: measurementUnits,
      products: products,
      productIngredients: productIngredients,
      productVariants: productVariants,
      variants: variants,
    });
  }, []);
  useEffect(() => {
    if (deps) {
      console.log('measurementUnit:', deps.selectColumn({
        select: 'measurementUnits.measurementUnit',
        where: 'productVariants.id',
        value: '125',
        log: false
      }));
    }
  }, [deps]);

  /**
   * Getters
   */
  const getViewComponent = () => {
    if (views[view]) {
      const View = views[view].component;
      return (
        <View
          data={data}
          deps={deps}
          idCategory={idCategory}
          onIdCategoryChange={handleIdCategoryChange}
          onCartAddProductVariant={handleCartAddProductVariant}
          sx={{py: 1}}
        />
      );
    } else {
      return <Typography variant='h4' color='error' align='center' sx={{my: 3, fontWeight: 'bold'}}>Pagina '{view}' este in lucru!</Typography>
    }
  };

  /**
   * Handlers
   */
  const handleIdCategoryChange = idCategory => () => {
    setIdCategory(idCategory);
    const anchor = document.querySelector('#backToTopAnchor');
    if (anchor)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleCartAddProductVariant = productVariant => () => {
    setData({
      ...data,
      cart: data.cart.concat(productVariant)
    });
  };

  /**
   * Render
   */
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container fixed disableGutters maxWidth='md' sx={{pb: 2}}>
        <img id='backToTopAnchor' src='images/Banner.png' alt='Banner' style={{width: '100%', minHeight: '25vh', maxHeight: '30vh', objectFit: 'cover'}} />

        <ButtonGroup variant='text' sx={{display: 'flex', justifyContent: 'space-evenly', borderWidth: 0}}>
          <Button variant={view === 'Home' ? 'contained' : 'text'} onClick={() => setView('Home')} style={{border: 'none'}}><HomeIcon /></Button>
          <Button variant={view === 'Menu' ? 'contained' : 'text'} onClick={() => {setIdCategory(null); setView('Menu');}} sx={{fontWeight: 'bold'}} style={{border: 'none'}}>Meniu</Button>
          <Button variant={view === 'Contact' ? 'contained' : 'text'} onClick={() => setView('Contact')} sx={{fontWeight: 'bold'}} style={{border: 'none'}}>Contact</Button>
          <Button variant={view === 'Photos' ? 'contained' : 'text'} onClick={() => setView('Photos')} sx={{fontWeight: 'bold'}} style={{border: 'none'}}>Foto</Button>
          <Button variant={view === 'ShoppingCart' ? 'contained' : 'text'} color='info' style={{border: 'none'}}
            disabled={true || !Boolean(data.cart.length)}
            onClick={() => setView('ShoppingCart')}
          >
            <Badge badgeContent={data.cart.length} color='secondary'>
              <ShoppingCartIcon />
            </Badge>
          </Button>
        </ButtonGroup>

        {getViewComponent()}

        <Typography variant='body2' align='center' sx={{py: 1, borderTop: '1px solid lightgrey'}}>Toate drepturile rezervate ©2011-{new Date().getFullYear()} Integrated Software SRL</Typography>

        <a href='https://m.do.co/c/729cbe834482' target='_blank' rel='noreferrer'>
          <img src='images/proudlyHostedByDigitalOcean_black.png' alt='Proudly hosted by Digital Ocean' style={{display: 'block', margin: 'auto', width: '240px'}} />
        </a>

        <ScrollToTop {...props}>
          <Fab size='medium' color='primary'>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      </Container>
    </ThemeProvider>
  );
}

export default App;