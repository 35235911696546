import '../Utils';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Home = props => {
  /**
   * States
   */

  /**
   * Hooks
   */

  /**
   * Getters
   */

  /**
   * Render
   */
  return (
    <Box sx={props.sx}>
      <CardContent>
        <Typography variant='h6' color='primary' align='center' sx={{fontWeight: 'bold'}}>Pizzeria Myo Modern Tulcea</Typography>
      </CardContent>
      <CardContent>
        <Typography variant='subtitle1' color='secondary' align='center' sx={{fontWeight: 'bold'}}>Stimati clienti, va aducem la cunostinta ca momentan nu mai facem livrari la domiciliu, in schimb va puteti ridica comenzile direct din restaurantul nostru.</Typography>
      </CardContent>
      <CardContent>
        <Typography variant='subtitle1' color='primary' align='center' sx={{fontWeight: 'bold'}}>Va multumim pentru intelegere!</Typography>
      </CardContent>
    </Box>
  );
}

export { Home };