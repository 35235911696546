import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';

const ScrollToTop = props => {
  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#backToTopAnchor');
    if (anchor)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Zoom in={useScrollTrigger({disableHysteresis: true, threshold: 100})}>
      <div onClick={handleClick} style={{position: 'fixed', bottom: 16, right: 16}} >
        {props.children}
      </div>
    </Zoom>
  );
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired
};

export default ScrollToTop;