import { useState } from 'react';
import '../JsSql';
import '../Utils';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { firstBy } from 'thenby';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const Menu = props => {
  /**
   * States
   */
  const [showHelp, setShowHelp] = useState(false);

  /**
   * Hooks
   */
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

  /**
   * Getters
   */
  const getCategories = () => props.deps.categories
    .sort(firstBy('position'));
  const getProducts = (idCategory = props.idCategory) => props.deps.products
    .filter(product => product.idCategory === idCategory)
    .sort(firstBy('position'));
  const getProductIngredients = idProduct => props.deps.productIngredients
    .filter(productIngredient => productIngredient.idProduct === idProduct)
    .sort(firstBy('position'));
  const getProductVariants = idProduct => props.deps.productVariants
    .filter(productVariant => productVariant.idProduct === idProduct)
    .sort(firstBy(value => Boolean(value.idVariant) ? getVariantPositionById(value.idVariant) : 0));

  const getCategoryById = idCategory => props.deps.categories.getValueById(idCategory, 'category');
  // const getCategoryIdMeasurementUnitById = idCategory => props.deps.categories.getValueById(idCategory, 'idMeasurementUnit');
  // const getCategoryQuantityById = idCategory => props.deps.categories.getValueById(idCategory, 'quantity');
  // const getCategoryQuantityByIdProduct = idProduct => getCategoryQuantityById(getProductIdCategoryById(idProduct));
  // const getCategoryQuantityByIdProduct = idProduct => props.deps.selectColumn({
  //   select: 'categories.quantity',
  //   where: 'products.id',
  //   value: idProduct
  // });
  const getIngredientById = idIngredient => props.deps.ingredients.getValueById(idIngredient, 'ingredient');
  const getIngredientIsAllergenById = idIngredient => props.deps.ingredients.getValueById(idIngredient, 'isAllergen');
  const getIngredientIsFrozenById = idIngredient => props.deps.ingredients.getValueById(idIngredient, 'isFrozen');
  // const getProductIdCategoryById = idProduct => props.deps.products.getValueById(idProduct, 'idCategory');
  const getMeasurementUnitById = idMeasurementUnit => props.deps.measurementUnits.getValueById(idMeasurementUnit, 'measurementUnit');
  // const getMeasurementUnitByIdProduct = idProduct => getMeasurementUnitById(getCategoryIdMeasurementUnitById(getProductIdCategoryById(idProduct)));
  const getMeasurementUnitByIdProduct = idProduct => props.deps.selectColumn({
    select: 'measurementUnits.measurementUnit',
    where: 'products.id',
    value: idProduct
  });
  const getMeasurementUnitIsSpaceRequiredById = idMeasurementUnit => props.deps.measurementUnits.getValueById(idMeasurementUnit, 'isSpaceRequired');
  // const getMeasurementUnitIsSpaceRequiredByIdProduct = idProduct => getMeasurementUnitIsSpaceRequiredById(getCategoryIdMeasurementUnitById(getProductIdCategoryById(idProduct)));
  const getMeasurementUnitIsSpaceRequiredByIdProduct = idProduct => props.deps.selectColumn({
    select: 'measurementUnits.isSpaceRequired',
    where: 'products.id',
    value: idProduct
  });
  const getVariantPositionById = idVariant => props.deps.variants.getValueById(idVariant, 'position');
  const getVariantById = idVariant => props.deps.variants.getValueById(idVariant, 'variant');

  /**
   * Render
   */
  if (props.deps === null)
    return null;

  return (
    <Box sx={props.sx}>
      <Grid container spacing={1}>
        {props.idCategory && props.idCategory === '1' &&
          <Grid item xs={12}>
            <Typography variant='h6' color='secondary' align='center' sx={{py: '8px', fontWeight: 'bold', color: 'white', backgroundColor: 'secondary.light', borderRadius: '8px'}}>Pentru fiecare pizza comandata, se percepe o taxa de 5 RON reprezentand ambalajul!</Typography>
          </Grid>
        }

        {props.idCategory &&
          <Grid item xs={12} sm={6} md={4} sx={{display: 'flex'}} onClick={props.onIdCategoryChange(null)}>
            <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', cursor: 'pointer'}}>
              <CardContent>
                <Typography variant='h4' color='secondary' align='center' sx={{fontWeight: 'bold'}}>{getCategoryById(props.idCategory)}</Typography>
                <CardMedia image='images/icon_back_128.png' alt='Inapoi la categorii' sx={{width: 128, height: 128, margin: 'auto'}} />
                <Typography variant='subtitle1' color='secondary' align='center' sx={{fontWeight: 'bold'}}>Inapoi la categorii</Typography>
              </CardContent>
            </Card>
          </Grid>
        }

        {props.idCategory
          ? getProducts().map(product =>
              <Grid key={product.id} item xs={12} sm={6} md={4} sx={{display: 'flex'}}>
                <Card raised sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <CardHeader sx={{py: 1}}
                    title={<Typography variant='subtitle1' color='primary' sx={{fontWeight: 'bold', letterSpacing: '-0.6px'}}>{product.product}</Typography>}
                  />
                  <CardMedia style={{height: 0, paddingTop: `${9 / 16 * 100}%`}}
                    image={product.image ? `images/Products/${product.image}` : 'images/icon_noImageAvailable_128.jpg'}
                    alt={product.image}
                  />
                  {Boolean(getProductIngredients(product.id).length) &&
                    <CardContent sx={{pt: 1, pb: 0}}>
                      {getProductIngredients(product.id).map(productIngredient =>
                        <Chip key={productIngredient.id} variant='outlined' size='small' sx={{mr: 0.25, mb: 0.25}}
                          label={`${productIngredient.quantity}${productIngredient.idMeasurementUnit ? `${getMeasurementUnitIsSpaceRequiredById(productIngredient.idMeasurementUnit) ? ' ' : ''}${getMeasurementUnitById(productIngredient.idMeasurementUnit)}` : ''} ${getIngredientById(productIngredient.idIngredient)}`}
                          color={getIngredientIsAllergenById(productIngredient.idIngredient) ? 'warning' : 'default'}
                          icon={getIngredientIsFrozenById(productIngredient.idIngredient) ? <AcUnitIcon fontSize='small' style={{color: theme.palette.info.main}} /> : null}
                          deleteIcon={getIngredientIsAllergenById(productIngredient.idIngredient) || getIngredientIsFrozenById(productIngredient.idIngredient) ? <HelpOutlineIcon /> : null}
                          onDelete={getIngredientIsAllergenById(productIngredient.idIngredient) || getIngredientIsFrozenById(productIngredient.idIngredient) ? () => setShowHelp(true) : null}
                        />
                      )}
                    </CardContent>
                  }
                  {Boolean(product.description) &&
                    <CardContent sx={{pt: 1, pb: 0}}>
                      <Typography variant='body2'>{product.description}</Typography>
                    </CardContent>
                  }
                  <CardContent sx={{mt: 'auto', pt: 1, pb: 0}}>
                    {getProductVariants(product.id).map(productVariant =>
                      <Box key={productVariant.id} sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                        {Boolean(productVariant.idVariant) &&
                          <Typography variant='body2' sx={{mr: 0.5}}>{productVariant.idVariant ? getVariantById(productVariant.idVariant) : ''}</Typography>
                        }
                        {Boolean(productVariant.idVariant) && Boolean(productVariant.quantity) &&
                          <Typography variant='body2'>{`(${productVariant.quantity}${getMeasurementUnitIsSpaceRequiredById(productVariant.idMeasurementUnit) ? ' ' : ''}${getMeasurementUnitById(productVariant.idMeasurementUnit)})`}</Typography>
                        }
                        {!Boolean(productVariant.idVariant) && Boolean(productVariant.quantity) &&
                          <Typography variant='body2'>{`${productVariant.quantity}${getMeasurementUnitIsSpaceRequiredById(productVariant.idMeasurementUnit) ? ' ' : ''}${getMeasurementUnitById(productVariant.idMeasurementUnit)}`}</Typography>
                        }
                        {/*
                        {Boolean(productVariant.idVariant) && !Boolean(productVariant.quantity) && Boolean(getCategoryQuantityByIdProduct(productVariant.idProduct)) &&
                          <Typography variant='body2'>{`(${getCategoryQuantityByIdProduct(productVariant.idProduct)}${getMeasurementUnitIsSpaceRequiredByIdProduct(productVariant.idProduct) ? ' ' : ''}${getMeasurementUnitByIdProduct(productVariant.idProduct)})`}</Typography>
                        }
                        {!Boolean(productVariant.idVariant) && !Boolean(productVariant.quantity) && Boolean(getCategoryQuantityByIdProduct(productVariant.idProduct)) &&
                          <Typography variant='body2'>{`${getCategoryQuantityByIdProduct(productVariant.idProduct)}${getMeasurementUnitIsSpaceRequiredByIdProduct(productVariant.idProduct) ? ' ' : ''}${getMeasurementUnitByIdProduct(productVariant.idProduct)}`}</Typography>
                        }
                        */}

                        <Button variant='contained' size='small' startIcon={<AddShoppingCartIcon />} sx={{ml: 'auto'}}
                          color={Boolean(props.data.cart.filter(value => value.id === productVariant.id).length) ? 'info' : 'info'}
                          onClick={props.onCartAddProductVariant(productVariant)}
                        >
                          <Badge badgeContent={props.data.cart.filter(value => value.id === productVariant.id).length} color='secondary'>
                            {`${productVariant.price} RON`}
                          </Badge>
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                  <Box /> {/* Padding bottom CSS fix */}
                </Card>
              </Grid>
            )
          : getCategories().map(category =>
              <Grid key={category.id} item xs={12} sm={6} md={4} sx={{display: 'flex'}} onClick={props.onIdCategoryChange(category.id)}>
                <Card raised sx={{display: 'flex', flexDirection: 'column', width: '100%', cursor: 'pointer'}}>
                  <CardHeader sx={{py: 1}}
                    title={
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant='subtitle1' color='primary' sx={{fontWeight: 'bold'}}>{category.category}</Typography>
                        <Typography variant='body2' color='GrayText' sx={{ml: 'auto', fontWeight: 'bold'}}>{`${getProducts(category.id).length} produs${getProducts(category.id).length !== 1 ? 'e' : ''}`}</Typography>
                      </Box>
                    }
                  />
                  <CardMedia style={{height: 0, paddingTop: `${9 / 16 * 100}%`}}
                    image={category.image ? `images/Categories/${category.image}` : 'images/icon_noImageAvailable_128.jpg'}
                    alt={category.image}
                  />
                  {Boolean(category.description)
                    ? <CardContent sx={{py: 1}}>
                        <Typography variant='body2'>{category.description}</Typography>
                      </CardContent>
                    : <CardContent sx={{py: 1}} />
                  }
                  <Box /> {/* Padding bottom CSS fix */}
                </Card>
              </Grid>
            )
        }

        {props.idCategory &&
          <Grid item xs={12} sm={6} md={4} sx={{display: 'flex'}} onClick={props.onIdCategoryChange(null)}>
            <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', cursor: 'pointer'}}>
              <CardContent>
                <Typography variant='h4' color='secondary' align='center' sx={{fontWeight: 'bold'}}>{getCategoryById(props.idCategory)}</Typography>
                <CardMedia image='images/icon_back_128.png' alt='Inapoi la categorii' sx={{width: 128, height: 128, margin: 'auto'}} />
                <Typography variant='subtitle1' color='secondary' align='center' sx={{fontWeight: 'bold'}}>Inapoi la categorii</Typography>
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>

      <Dialog fullScreen={fullScreen}
        open={showHelp}
        onClose={() => setShowHelp(false)}
      >
        <DialogTitle>
          <Typography component='div' variant='h5'>Ingrediente</Typography>
          <Typography component='div' variant='h6'>Alergeni si produse congelate</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display: 'flex', alignItems: 'center', mb: 3}}>
            <Box component='span' sx={{flexBasis: '80px', flexShrink: 0, mr: 2}}>
              <Chip component='span' variant='outlined' size='small'
                label='50g rosii'
                color='default'
              />
            </Box>
            <Typography component='span' variant='body2'>Produsul contine acest ingredient in cantitatea specificata (cantitatea unui ingredient nu este specificata intotdeauna)</Typography>
          </DialogContentText>
          <DialogContentText sx={{display: 'flex', alignItems: 'center', mb: 3}}>
            <Box component='span' sx={{flexBasis: '80px', flexShrink: 0, mr: 2}}>
                <Chip component='span' variant='outlined' size='small'
                label='branza'
                color='warning'
              />
            </Box>
            <Typography component='span' variant='body2'>Acest ingredient este inclus pe lista alergenilor</Typography>
          </DialogContentText>
          <DialogContentText sx={{display: 'flex', alignItems: 'center', mb: 3}}>
            <Box component='span' sx={{flexBasis: '80px', flexShrink: 0, mr: 2}}>
                <Chip component='span' variant='outlined' size='small'
                label='mazare'
                color='default'
                icon={<AcUnitIcon fontSize='small' style={{color: theme.palette.info.main}} />}
              />
            </Box>
            <Typography component='span' variant='body2'>Acest ingredient a fost congelat si decongelat inainte de preparare</Typography>
          </DialogContentText>
          <DialogContentText sx={{display: 'flex', alignItems: 'center', mb: 3}}>
            <Box component='span' sx={{flexBasis: '80px', flexShrink: 0, mr: 2}}>
                <Chip component='span' variant='outlined' size='small'
                label='lipie'
                color='warning'
                icon={<AcUnitIcon fontSize='small' style={{color: theme.palette.info.main}} />}
              />
            </Box>
            <Typography component='span' variant='body2'>Acest ingredient este inclus pe lista alergenilor si a fost congelat si decongelat inainte de preparare</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant='contained' size='small' color='info' onClick={() => setShowHelp(false)}>Inchide</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export { Menu };